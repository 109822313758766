import React, {Fragment, useState, useEffect} from 'react';
import { Segment, Menu, Icon, Progress, Dropdown } from 'semantic-ui-react';


const MenuAvancement = ({ModifierPoint,Play,run,xMax,pointActuel,nbPoints}) => {

    const [activeItem, setactiveItem] = useState("play")

    const [playVisible, setplayVisible] = useState(true)

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    useEffect(() => {

      window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])
    

    useEffect(() => {
        if(run === "true"){
          setplayVisible(true);
        }
      }, [run])


    useEffect(() => {
        if(pointActuel+1 >= nbPoints){
          setplayVisible(true);
        }
      }, [pointActuel])
    

    function change(e,{name}){
        setactiveItem(name);

        if(name === "backward"){
            if(pointActuel > 0){
                ModifierPoint(pointActuel-1);
            }
        }else if(name === "step backward"){
            ModifierPoint(0,0);
        }else if(name === "step forward"){
            ModifierPoint(nbPoints);
        }else if(name === "forward"){
            if(pointActuel+1 <= nbPoints){
                ModifierPoint(pointActuel+1);
            }
        }
    }

    function changePlay(e,{name}){
        setplayVisible(!playVisible);
        setactiveItem(name);

        if(pointActuel === nbPoints){
            setplayVisible(true);
            setactiveItem(name);
        }

        if(name === "play"){
            Play(true);
        }else{
            Play(false);
        }
    }

    if(isMobile === false){
    return ( 
        <Segment style={{display : "flex", flexDirection : "row", alignItems : "flex-start", padding : "0.5em 1em"}}>
            <Menu icon compact style={{flex: "initial"}} size={isMobile === false ? "large" : "small"}>
                <Menu.Item>
                    Avancement
                </Menu.Item>
                
                <Fragment>
                            <Menu.Item
                                name='step backward'
                                active={activeItem === 'step backward'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='step backward' />
                            </Menu.Item>
                            <Menu.Item
                                name='backward'
                                active={activeItem === 'backward'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='backward' />
                            </Menu.Item>
                </Fragment>
                
                {
                    playVisible === true ?
                    (
                        <Menu.Item
                            name='play'
                            active={activeItem === 'play'}
                            onClick={(e,{name}) => changePlay(e,{name})}
                            >
                            <Icon name='play' />
                        </Menu.Item>
                    ) : (
                        <Menu.Item
                            name='pause'
                            active={activeItem === 'pause'}
                            onClick={(e,{name}) => changePlay(e,{name})}
                            >
                            <Icon name='pause' />
                        </Menu.Item>
                    )
                }
                
                <Fragment>
                            <Menu.Item
                                name='forward'
                                active={activeItem === 'forward'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='forward' />
                            </Menu.Item>
                            <Menu.Item
                                name='step forward'
                                active={activeItem === 'step forward'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='step forward' />
                            </Menu.Item>
                </Fragment>
            </Menu>
            <Progress progress percent={pointActuel > 0 ? parseFloat(pointActuel*100/nbPoints).toFixed(2) : 0} color='red' style={{flex: "auto", marginLeft: "0.8em", marginBottom : isMobile === false ? "0em" : "1em" , marginTop: isMobile === false ? "0.8em" : "1em"}} size={isMobile === false ? "medium" : "small"}/>
        </Segment>
     )
    }else{
        return (
        <Segment style={{display : "flex", flexDirection : "column", alignSelf: "center", padding : "0.5em 1em"}}>
        <Menu icon compact style={{flex: "initial"}} size={isMobile === false ? "large" : "small"}>
            <Menu.Item>
                Avancement
            </Menu.Item>
            
            <Fragment>
                        <Menu.Item
                            name='step backward'
                            active={activeItem === 'step backward'}
                            onClick={(e,{name}) => change(e,{name})}
                            >
                            <Icon name='step backward' />
                        </Menu.Item>
                        <Menu.Item
                            name='backward'
                            active={activeItem === 'backward'}
                            onClick={(e,{name}) => change(e,{name})}
                            >
                            <Icon name='backward' />
                        </Menu.Item>
            </Fragment>
            
            {
                playVisible === true ?
                (
                    <Menu.Item
                        name='play'
                        active={activeItem === 'play'}
                        onClick={(e,{name}) => changePlay(e,{name})}
                        >
                        <Icon name='play' />
                    </Menu.Item>
                ) : (
                    <Menu.Item
                        name='pause'
                        active={activeItem === 'pause'}
                        onClick={(e,{name}) => changePlay(e,{name})}
                        >
                        <Icon name='pause' />
                    </Menu.Item>
                )
            }
            
            <Fragment>
                        <Menu.Item
                            name='forward'
                            active={activeItem === 'forward'}
                            onClick={(e,{name}) => change(e,{name})}
                            >
                            <Icon name='forward' />
                        </Menu.Item>
                        <Menu.Item
                            name='step forward'
                            active={activeItem === 'step forward'}
                            onClick={(e,{name}) => change(e,{name})}
                            >
                            <Icon name='step forward' />
                        </Menu.Item>
            </Fragment>
        </Menu>
        <Progress progress percent={pointActuel > 0 ? parseFloat(pointActuel*100/nbPoints).toFixed(2) : 0} color='red' style={{flex: "auto", marginBottom : "0em" , marginTop: isMobile === false ? "0.6em" : "1em"}} size={"medium"}/>
    </Segment>
    )
    }
}
 
export default MenuAvancement;