import React, {useState, useEffect} from 'react';
import { Segment, Menu, Icon, Dropdown } from 'semantic-ui-react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar, Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const colors = ['rgba(255, 99, 132, 0.7)',
                'rgba(54, 162, 235, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(75, 192, 192, 0.7)',
                'rgba(153, 102, 255, 0.7)',
                'rgba(255, 159, 64, 0.7)',
                'rgba(113, 122, 255, 0.7)',
                'rgba(25, 236, 96, 0.7)'];

const options = [
    {
        key: '1',
        text: 'Δx grand',
        value: '20',
    },
    {
        key: '5',
        text: 'Δx moyen',
        value: '50',
    },
    {
        key: '10',
        text: 'Δx  petit',
        value: '100',
    },
    ]

const charToReplace = ["&nbsp;","_20","_19","_18","_17","_16","_15","_14","_13","_12","_11","_10","_9","_8","_7","_6","_5","_4","_3","_2","_1"," -"," 2-"," 3-"," 4-"," +"," 2+"," 3+","4+","c","h","o","s","n","al","AL","aL","br","BR","bR","cl","cL","CL","i","NA","nA","na","k","mn","mN","MN","cu","cU","CU","li","LI","lI","ba","bA","BA","mg","mG","MG","ZN","zN","zn","cr","CR","cR","cd","CD","cD","NI","ni","nI","sn","SN","sN","pb","pB","PB","HG","hg","hG","ag","aG","AG","pt","PT","pT","au","aU","AU"];

const replaceWithChar = ["","₂₀","₁₉","₁₈","₁₇","₁₆","₁₅","₁₄","₁₃","₁₂","₁₁","₁₀","₉","₈","₇","₆","₅","₄","₃","₂","₁","⁻","²⁻","³⁻","⁴⁻","⁺","²⁺","³⁺","⁴⁺","C","H","O","S","N","Al","Al","Al","Br","Br","Br","Cl","Cl","Cl","I","Na","Na","Na","K","Mn","Mn","Mn","Cu","Cu","Cu","Li","Li","Li","Ba","Ba","Ba","Mg","Mg","Mg","Zn","Zn","Zn","Cr","Cr","Cr","Cd","Cd","Cd","Ni","Ni","Ni","Sn","Sn","Sn","Pb","Pb","Pb","Hg","Hg","Hg","Ag","Ag","Ag","Pt","Pt","Pt","Au","Au","Au"];

const Graph = ({reactionActuel,nbPoints, xMax,changerNbPoints,pointActuel}) => {

    const [activeItem, setactiveItem] = useState("chart bar")

    const [label, setlabel] = useState([])

    const [label2, setlabel2] = useState([])

    const [label3, setlabel3] = useState([])

    const [mole, setmole] = useState([])

    const [listReactifs, setlistReactifs] = useState([])

    const [listProduits, setlistProduits] = useState([])

    const [nombrePoints, setnombrePoints] = useState('20')

    const [nMax, setnMax] = useState(0)

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {

        let largeur = document.getElementById("labox").clientWidth;

        if(width < 800){
            setWidth(largeur-20);
        }

    }

    function changerEcritureFormule(value){

        let newString = replaceMulCharInString(value, charToReplace, replaceWithChar);

        if(newString.length === 0){
            newString = "&nbsp;";
        }
        
        return newString;
    }

    function replaceMulCharInString(_string, charToReplace, replaceWith)
    {
        for (let i = 0; i < charToReplace.length; i++) {
            _string = _string.replaceAll(charToReplace[i], replaceWith[i]);
        }
        return _string;
    }

    useEffect(() => {
      if(localStorage.getItem('activeGraph') !== null){
          setactiveItem(localStorage.getItem('activeGraph'));
      }
      window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])
    

    useEffect(() => {

      let leslabels = [];
      let leslabels2 = [];
      let lesmoles = [];    

      let leslabels3 = [];

      setlistReactifs( reactionActuel.reactifs.map( elem => []) );
      setlistProduits( reactionActuel.produits.map( elem => []) );

      let indLabel = 0;

        while(indLabel <= pointActuel){
            leslabels2.push(String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            leslabels3.push("x = " + String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            indLabel += 1;
        }

      let lesreactifs = [...listReactifs];

      reactionActuel.reactifs.map( (elem,index) => {

        leslabels.push(changerEcritureFormule(elem.formule));

        lesmoles.push(parseFloat(elem.nbmole)-parseFloat(elem.nbstochiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesreactifs[index] = [];

        let ind = 0;

        while(ind <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbmole)-parseFloat(elem.nbstochiometrique)*parseFloat(xMax*ind/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesreactifs[index].push(valeurMoleBis);
            }         
            ind += 1;
        }

      });

      setlistReactifs(lesreactifs);

      let lesproduits = [...listProduits];

      reactionActuel.produits.map( (elem,index) => {

        leslabels.push(changerEcritureFormule(elem.formule));

        lesmoles.push(parseFloat(elem.nbstochiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesproduits[index] = [];

        let indProd = 0;

        while(indProd <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbstochiometrique)*parseFloat(xMax*indProd/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesproduits[index].push(valeurMoleBis);
            }        
            indProd += 1;
        }

      })

      setlistProduits(lesproduits);

      setlabel(leslabels);
      setlabel2(leslabels2);
      setlabel3(leslabels3);
   
      setmole(lesmoles);

      let a = 0;
      let mmax = 0;

      while(a < reactionActuel.produits.length){
            let vale = reactionActuel.produits[a].nbstochiometrique*xMax;
            if(vale > mmax){
                mmax = vale;
            }
            a++;
      }

      let b = 0;

      while(b < reactionActuel.reactifs.length){
        let vale = reactionActuel.reactifs[b].nbmole;
        if(vale > mmax){
            mmax = vale;
        }
        b++;
      }

      setnMax(mmax);

    }, [reactionActuel])


    useEffect(() => {

      setnombrePoints(String(nbPoints));

      let lesmoles = [];

      let leslabels2 = [];

      let leslabels3 = [];
   
      let indLabel = 0;

        while(indLabel <= pointActuel){
            leslabels2.push(String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            leslabels3.push("x = " + String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            indLabel += 1;
        }

      let lesreactifs = [...listReactifs];

      reactionActuel.reactifs.map( (elem,index) => {

        lesmoles.push(parseFloat(elem.nbmole)-parseFloat(elem.nbstochiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesreactifs[index] = [];

        let ind = 0;

        while(ind <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbmole)-parseFloat(elem.nbstochiometrique)*parseFloat(xMax*ind/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesreactifs[index].push(valeurMoleBis);
            }         
            ind += 1;
        }

      });

      setlistReactifs(lesreactifs);

      let lesproduits = [...listProduits];

      reactionActuel.produits.map( (elem,index) => {

        lesmoles.push(parseFloat(elem.nbstochiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesproduits[index] = [];

        let indProd = 0;

        while(indProd <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbstochiometrique)*parseFloat(xMax*indProd/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesproduits[index].push(valeurMoleBis);
            }         
            indProd += 1;
        }

      })

      setlistProduits(lesproduits);
      setmole(lesmoles);

      setlabel2(leslabels2);

      setlabel3(leslabels3);

      let a = 0;
      let mmax = 0;

      while(a < reactionActuel.produits.length){
            let vale = reactionActuel.produits[a].nbstochiometrique*xMax;
            if(vale > mmax){
                mmax = vale;
            }
            a++;
      }

      let b = 0;

      while(b < reactionActuel.reactifs.length){
        let vale = reactionActuel.reactifs[b].nbmole;
        if(vale > mmax){
            mmax = vale;
        }
        b++;
      }

      setnMax(mmax);

    }, [pointActuel,nbPoints,xMax,reactionActuel])

    function change(e,{name}){
        setactiveItem(name);
        localStorage.setItem('activeGraph', name)
    }

    function handleNbPoints(e,data){
        setnombrePoints(data.value);
        changerNbPoints(data.value);
    }

    function changerFormule(value){

        let charToReplace = [".","e-6","e-5","e-4","e-3","e-2","e-1","e+0","e+1","e+2","e+3","e+4","e+5","e+6"];
        let replaceWithChar = [",","×10⁻⁶","×10⁻⁵","×10⁻⁴","×10⁻³","×10⁻²","×10⁻¹","","×10¹","×10²","×10³","×10⁴","×10⁵","×10⁶"];
        let newString = replaceMulCharInString(value, charToReplace, replaceWithChar);
        
        return newString;
    }

    return ( 
        <Segment id="labox" style={{display : "flex", flexDirection : "column", alignItems : "stretch", padding : "0.5em 1em" }}>
            <div style={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between"}}>
                        <Menu icon compact>
                            <Menu.Item
                                name='chart bar'
                                active={activeItem === 'chart bar'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='chart bar' />
                            </Menu.Item>
                            <Menu.Item
                                name='chart line'
                                active={activeItem === 'chart line'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='chart line' />
                            </Menu.Item>
                        </Menu>
                        <Menu compact position='right'>
                        <Dropdown          
                            selection
                            compact
                            value={nombrePoints} 
                            options={options}
                            onChange={(e,data) => handleNbPoints(e,data)}
                        />
                        </Menu>
            </div>
                
                     
            <div style={{position: "relative" , margin: "0 auto", padding: "10px", fontSize: "30", width: "80%"}}>
            {
                activeItem === "chart bar" && reactionActuel !== null ? (
                    <Bar
                    data={{
                        labels: label,
                        datasets: [
                            {
                                backgroundColor: reactionActuel.reactifs.map( elem => 'rgba(53, 162, 235, 0.7)').concat(reactionActuel.produits.map( elem => 'rgba(255, 99, 132, 0.7)')),
                                data: mole
                            }
                        ]}}
                    options={{
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return String(changerFormule(context.parsed.y.toExponential(2))).replace(".",",") + ' mol';
                                    }
                                }
                            }
                        },
                        scales: {
                            y: {
                                max: nMax,
                                min: 0,
                                ticks: {
                                    callback: function(value, index, ticks) {
                                        return String(value).replace(".",",") + " mol";
                                    }
                                }
                            }
                        },
                        title:{
                            display:false,
                            text:'',
                            fontSize:40,
                            fontWeight: "bold"
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: 30,
                                    weight: "bold"
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        responsive : true,
                    }}
                    width={500} 
                    height={200}
                    />
                ) : (
                    <Line
                    id="laligne"
                    height={200}
                    data={{
                        labels: label2,
                        datasets: reactionActuel.reactifs.map( (elem,i) => ({
                            label : changerEcritureFormule(elem.formule),
                            backgroundColor : colors[i],
                            borderColor : colors[i],
                            data : listReactifs[i],
                            labels : label3[i],
                        })).concat(reactionActuel.produits.map( (elem,i) => ({
                            label : changerEcritureFormule(elem.formule),
                            backgroundColor : colors[i+reactionActuel.reactifs.length],
                            borderColor : colors[i+reactionActuel.reactifs.length],
                            data : listProduits[i],
                            labels : label3[i+reactionActuel.reactifs.length],
                        })))
                    }}
                    options={{
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return String(changerFormule(context.parsed.y.toExponential(2))).replace(".",",") + ' mol';
                                    },
                                    title: function(context) {
                                        let title = context[0].label;
                                        return "x = " + title;
                                    }
                                }
                            }
                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: function(value, index, ticks) {
                                        return String(value).replace(".",",") + " mol";
                                    }
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        responsive : true,
                        title:{
                            display:false,
                            text:'',
                            fontSize:40,
                            fontWeight: "bold"
                        },
                        animation: true,
                    }}
                    />
                )
            }
            
            </div>
        </Segment>
     );
}
 
export default Graph;