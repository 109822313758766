import React, {useState, useEffect, Fragment} from 'react';
import { Modal, Button, Form, Menu, Table, Icon, Popup } from 'semantic-ui-react';
import shortid from  "shortid";
import {listReactionDefault} from './Create.js'

const options = [
    { key: '1', text: '1', value: '1' },
    { key: '2', text: '2', value: '2' },
    { key: '3', text: '3', value: '3' },
    { key: '4', text: '4', value: '4' },
  ];

const options2 = [
    { key: '1', text: '1', value: '1' },
    { key: '2', text: '2', value: '2' },
    { key: '3', text: '3', value: '3' },
    { key: '4', text: '4', value: '4' },
  ];

const options3 = [
    { key: '1', text: 'solide', value: 's' },
    { key: '2', text: 'liquide', value: 'l' },
    { key: '3', text: 'gaz', value: 'g' },
    { key: '4', text: 'aqueux', value: 'aq' },
  ];

const optionsReaction = [
    { key: '1', text: 'Autre réaction...', value: 'Autre réaction...' },
    { key: '2', text: 'Combustion du carbone', value: 'Combustion du carbone' },
    { key: '3', text: 'Combustion du méthane', value: 'Combustion du méthane' },
    { key: '4', text: 'Fer et acide chlorhydrique', value: 'Fer et acide chlorhydrique' },
    { key: '5', text: 'Whoosbottle', value: 'Whoosbottle' },
  ];


const charToReplace = ["_20","_19","_18","_17","_16","_15","_14","_13","_12","_11","_10","_9","_8","_7","_6","_5","_4","_3","_2","_1"," -"," 2-"," 3-"," 4-"," +"," 2+"," 3+","4+","c","h","o","s","n","al","AL","aL","br","BR","bR","cl","cL","CL","i","NA","nA","na","k","fe","FE","fE","MN","mN","mn","cu","cU","CU","li","LI","lI","ba","bA","BA","mg","mG","MG","ZN","zN","zn","cr","CR","cR","cd","CD","cD","NI","ni","nI","sn","SN","sN","pb","pB","PB","HG","hg","hG","ag","aG","AG","pt","PT","pT","au","aU","AU"];

const replaceWithChar = ["<sub>20</sub>","<sub>19</sub>","<sub>18</sub>","<sub>17</sub>","<sub>16</sub>","<sub>15</sub>","<sub>14</sub>","<sub>13</sub>","<sub>12</sub>","<sub>11</sub>","<sub>10</sub>","<sub>9</sub>","<sub>8</sub>","<sub>7</sub>","<sub>6</sub>","<sub>5</sub>","<sub>4</sub>","<sub>3</sub>","<sub>2</sub>","<sub>1</sub>","<sup>-</sup>","<sup>2-</sup>","<sup>3-</sup>","<sup>4-</sup>","<sup>+</sup>","<sup>2+</sup>","<sup>3+</sup>","<sup>4+</sup>","C","H","O","S","N","Al","Al","Al","Br","Br","Br","Cl","Cl","Cl","I","Na","Na","Na","K","Fe","Fe","Fe","Mn","Mn","Mn","Cu","Cu","Cu","Li","Li","Li","Ba","Ba","Ba","Mg","Mg","Mg","Zn","Zn","Zn","Cr","Cr","Cr","Cd","Cd","Cd","Ni","Ni","Ni","Sn","Sn","Sn","Pb","Pb","Pb","Hg","Hg","Hg","Ag","Ag","Ag","Pt","Pt","Pt","Au","Au","Au"];
  

const Edit = ({open,setopen,reactionActuel,editReaction,listNoms,noEditReaction}) => {

    const [nbreactif, setnbreactif] = useState(reactionActuel.reactifs.length)
    const [nbproduit, setnbproduit] = useState(reactionActuel.produits.length)

    const [lesreactifs, setlesreactifs] = useState(reactionActuel.reactifs)
    const [lesproduits, setlesproduits] = useState(reactionActuel.produits)

    const [nomReaction, setnomReaction] = useState(reactionActuel.name)

    const [pageReactif, setpageReactif] = useState(0)

    const [pageProduit, setpageProduit] = useState(0)

    const [width, setWidth] = useState(window.innerWidth);

    const [listDefault, setlistDefault] = useState([])

    const [disabledInput, setdisabledInput] = useState(false)

    const [autreReaction, setautreReaction] = useState("Autre réaction...")

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 850;

    useEffect(() => {

        let listNomsDefault = listReactionDefault.map( elem => elem.name);
        setlistDefault(listNomsDefault);

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
      
        if(listDefault.includes(reactionActuel.name)){
            setdisabledInput(true);
            setautreReaction(reactionActuel.name);
        }else{
            setautreReaction("Autre réaction...");
            setdisabledInput(false);
        }

        setnomReaction(reactionActuel.name);

        setnbreactif(reactionActuel.reactifs.length);
        setnbproduit(reactionActuel.produits.length);

        setlesreactifs(reactionActuel.reactifs);
        setlesproduits(reactionActuel.produits);

        setpageReactif(0)

        setpageProduit(0)

    }, [reactionActuel])
    
    async function handleReaction({value}){

      // on ne change pas la réaction
        
    }

    function changerEcritureFormuleReactif(value){

        let newString = replaceMulCharInString(value, charToReplace, replaceWithChar);

        if(newString.length === 0){
            newString = "&nbsp;";
        }
        
        return newString;
    }

    function changerEcritureFormuleProduit(value){

        let newString = replaceMulCharInString(value, charToReplace, replaceWithChar);

        if(newString.length === 0){
            newString = "&nbsp;";
        }
        
        return newString;
    }

    function replaceMulCharInString(_string, charToReplace, replaceWith)
    {
        for (let i = 0; i < charToReplace.length; i++) {
            _string = _string.replaceAll(charToReplace[i], replaceWith[i]);
        }
        return _string;
    }

    function Validate(e){
        let error = false;

        for (let i = 0; i < nbreactif; i++) {

            if(lesreactifs[i].formule === "" || lesreactifs[i].etat === "" || lesreactifs[i].nbmole === ""){
                error = true;
                break;
            }
            
        }

        for (let i = 0; i < nbproduit; i++) {

            if(lesproduits[i].formule === "" || lesproduits[i].etat === "" || lesproduits[i].nbmole === ""){
                error = true;
                break;
            }
            
        }

        if(error === false){

            //enregistrement

            let uneReaction = {
                id : reactionActuel.id,
                name : nomReaction,
                reactifs : lesreactifs,
                produits : lesproduits
              };

            editReaction(uneReaction);

            setopen(false);
        }
    }

    function Annuler(e){
        setopen(false);
        noEditReaction(reactionActuel);
    }
    
    function changerElementReactifEtat(id,{value}){
        let r = [...lesreactifs];
        r.map( element => {
            if(element.id === id){
                element.etat = value;
            }
        })
        setlesreactifs(r);
    }

    function changerElementProduitEtat(id,{value}){
        let r = [...lesproduits];
        r.map( element => {
            if(element.id === id){
                element.etat = value;
            }
        })
        setlesproduits(r);
    }

    function changerNbMoleReactif(id,{value}){
        let r = [...lesreactifs];
        r.map( element => {
            if(element.id === id){
                element.nbmole = value;
            }
        })
        setlesreactifs(r);
    }

    function changerNbMoleProduit(id,{value}){
        let rp = [...lesproduits];
        rp.map( element => {
            if(element.id === id){
                element.nbmole = value;
            }
        })
        setlesproduits(rp);
    }

    function changerNbStochiometriqueProduit(id,{value}){
        let rp = [...lesproduits];
        rp.map( element => {
            if(element.id === id){
                element.nbstochiometrique = value;
            }
        })
        setlesproduits(rp);
    }

    function changerNbStochiometriqueReactif(id,{value}){
        let rp = [...lesreactifs];
        rp.map( element => {
            if(element.id === id){
                element.nbstochiometrique = value;
            }
        })
        setlesreactifs(rp);
    }

    function changerFormuleReactif(id, {value}){
        
        let r = [...lesreactifs];

        r.map( element => {
            if(element.id === id){
                element.formule = value;
            }
        })
        setlesreactifs(r);
    }

    function changerFormuleProduit(id, {value}){
        let rp = [...lesproduits];

        rp.map( element => {
            if(element.id === id){
                element.formule = value;
            }
        })
        setlesproduits(rp);
    }

    function replaceMulCharInString(_string, charToReplace, replaceWith)
    {
        for (let i = 0; i < charToReplace.length; i++) {
            _string = _string.replaceAll(charToReplace[i], replaceWith[i]);
        }
        return _string;
    }

    function lostFocusNbMoleReactif(id,value){
        let r = [...lesreactifs];
        r.map( element => {
            if(element.id === id && value === ""){
                element.nbmole = "0";
            }
        })
        setlesreactifs(r);
    }

    function lostFocusNbMoleProduit(id,value){
        let r = [...lesproduits];
        r.map( element => {
            if(element.id === id && value === ""){
                element.nbmole = "0";
            }
        })
        setlesproduits(r);
    }

    function lostFocusNbStoechioReactif(id,value){
        let r = [...lesreactifs];
        r.map( element => {
            if(element.id === id && (value === "" || value === "0") ){
                element.nbstochiometrique = "1";
            }
        })
        setlesreactifs(r);
    }

    function lostFocusNbStoechioProduit(id,value){
        let r = [...lesproduits];
        r.map( element => {
            if(element.id === id && (value === "" || value === "0") ){
                element.nbstochiometrique = "1";
            }
        })
        setlesproduits(r);
    }

    function handleNbReactif({value}){

        if(disabledInput === false ){
            setnbreactif(parseInt(value));

            let list_reactif = [...lesreactifs];
            let ni = list_reactif.length;
            if(ni < parseInt(value)){
                let dif = parseInt(value) - ni;
                for (let i = 0; i < dif; i++) {
                    list_reactif.push({
                        formule : "",
                        etat : "s",
                        nbmole : "0",
                        id : `reactif${ni+i+1}`,
                        nbstochiometrique : "1",
                    });
                }
            }else if(ni > parseInt(value)){
                let dif = ni - parseInt(value);
                for (let j = 0; j < dif; j++) {
                    list_reactif.pop();
                }
            }
            
            setlesreactifs(list_reactif);

            if(nbreactif > pageReactif){
                setpageReactif(0);
            }
        }
    }

    function handleNbProduit({value}){

        if(disabledInput === false ){
            setnbproduit(parseInt(value));

            let list_produit = [...lesproduits];
            let ni = list_produit.length;
            if(ni < parseInt(value)){
                let dif = parseInt(value) - ni;
                for (let i = 0; i < dif; i++) {
                    list_produit.push({
                        formule : "",
                        etat : "s",
                        nbmole : "0",
                        id : `produit${ni+i+1}`,
                        nbstochiometrique : "1",
                    });
                }
            }else if(ni > parseInt(value)){
                let dif = ni - parseInt(value);
                for (let j = 0; j < dif; j++) {
                    list_produit.pop();
                }
            }

            setlesproduits(list_produit);

            if(nbproduit > pageProduit){
                setpageProduit(0);
            }
        }
    }

    function lostFocusNomReaction(){
        if(nomReaction === ""){
            setnomReaction(``);
        }
    }

    if(isMobile === false){
        return ( 
            <Modal
                open={open}
                style={{overflowX : "auto"}}
            >
                <Modal.Content>
                    <Form>
                        
                    <Form.Group widths='equal'>

                        <Form.Select
                            label="Choix de la réaction"
                            options={optionsReaction}
                            value={autreReaction}
                            onChange={(e,{value}) => handleReaction({value})}
                            onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                            onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                            trigger={<span>{autreReaction}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                        />

                        <Form.Input
                            label="Nom de la réaction"
                            value={nomReaction}
                            onChange={ (e) => { disabledInput === false && setnomReaction(e.target.value)}}
                            error={nomReaction === "" || listNoms.includes(nomReaction) === true}
                            maxLength="20"
                            onBlur={(e) => lostFocusNomReaction()}
                            icon={disabledInput === true ? 'lock' : 'unlock'}
                        />

                    </Form.Group>
                    
                    <Form.Group widths='equal'>
                    
                        <Form.Select
                            fluid
                            label='Nombre de réactif'
                            options={options}
                            onChange={ (e, {value}) => handleNbReactif({value})}
                            onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                            onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                            trigger={<span>{nbreactif}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                        />
                        
                        <Form.Select
                            fluid
                            label='Nombre de produits'
                            options={options2}
                            onChange={ (e, {value}) => handleNbProduit({value})}
                            onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                            onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                            trigger={<span>{nbproduit}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                        />
                    </Form.Group>
                </Form>

                    <Table celled unstackable singleLine color='blue'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center' width={1}>Le nb stœchio du réactif</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3} colSpan='2'>
                                    La formule du réactif
                                    <Popup 
                                        content="Losque vous entrez la formule dans l'encadré de gauche et que vous voulez mettre en indice ajoutez _ devant et un espace lorsqu'il sagit d'un exposant." 
                                        trigger={
                                            <Icon name="question circle" style={{backgroundColor : "transparent", padding : "0em 0em 0em 1em"}}/>
                                        }  
                                        />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={2}>Etat du réactif</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3}>Le nb de mole initial du réactif</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                        {
                            lesreactifs && lesreactifs.map( (element, i) => {
                                if(i === pageReactif){
                                return (
                                        <Table.Row key={`createreactif${i}`}>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Input
                                                style={{ width:"8em"}}
                                                key={`reactifstochiometrique{i}`} 
                                                value={element.nbstochiometrique}
                                                type="number"
                                                onChange={(e,{value}) => changerNbStochiometriqueReactif(element.id, {value})}
                                                onBlur={(e) => lostFocusNbStoechioReactif(element.id, element.nbstochiometrique)}
                                                readOnly={disabledInput}
                                                icon={disabledInput === true ? 'lock' : 'unlock'}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Input
                                                    style={{ width:"8em"}}
                                                    key={`reactifformule{i}`} 
                                                    value={element.formule}
                                                    type="text"
                                                    maxLength="18"
                                                    error={element.formule === ""}
                                                    onChange={(e,{value}) => changerFormuleReactif(element.id, {value})}
                                                    readOnly={disabledInput}
                                                    icon={disabledInput === true ? 'lock' : 'unlock'}
                                                />           
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <div
                                                    style={{ color : "rgba(0, 0, 0, 0.95)", backgroundColor : "rgb(255, 255, 255)" , width : "100%", outline: "0px solid", lineHeight: "17px", fontFamily : "fontFamily: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif", boxSizing : "borderBox", boxShadow : "rgba(0, 0, 0, 0) 0px 0px 0px 0", padding : "9.5px 14px", border : "1px", borderRadius : "4px", borderColor : "rgba(34, 36, 38, 0.15)", borderStyle : "solid"}}
                                                    dangerouslySetInnerHTML={{ __html: changerEcritureFormuleReactif(element.formule) }} >
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Select
                                                    style={{ width : "10em", margin : "auto"}}
                                                    fluid
                                                    options={options3}
                                                    value={element.etat}
                                                    error={element.etat === ""}
                                                    onChange={(e,{value}) => changerElementReactifEtat(element.id, {value})}
                                                    onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                                                    onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                                                    trigger={<span>{ element.etat === "s" ? "solide" : ( element.etat === "l" ? "liquide" : (element.etat === "g" ? "gaz" : "aqueux") )}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Input
                                                    style={{ width:"8em"}}
                                                    key={`reactifni{i}`} 
                                                    value={element.nbmole}
                                                    type="number"
                                                    onChange={(e,{value}) => changerNbMoleReactif(element.id, {value})}
                                                    onBlur={(e) => lostFocusNbMoleReactif(element.id, element.nbmole)}
                                                    icon={'unlock'}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                            })
                        }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5'>
                                <Menu floated='right' pagination size="tiny">
                                    <Menu.Item as='a' icon onClick={ (e) => { pageReactif > 0 && setpageReactif(pageReactif-1) }}>
                                        <Icon name='chevron left' />
                                    </Menu.Item>
                                    {
                                        lesreactifs && lesreactifs.map( (element, i) => {
                                            return (
                                                <Menu.Item key={`createmenureactif${i}`} onClick={(e) => setpageReactif(i)} active={pageReactif === i} as='a'>{i+1}</Menu.Item>
                                            )
                                        })
                                    }
                                    <Menu.Item as='a' icon onClick={ (e) => { pageReactif < lesreactifs.length-1 && setpageReactif(pageReactif+1) }}>
                                        <Icon name='chevron right' />
                                    </Menu.Item>
                                </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                    
                    <Table celled singleLine color='red'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center' width={1}>Le nb stœchio du produit</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3} colSpan='2'>
                                    La formule du produit
                                    <Popup 
                                        content="Losque vous entrez la formule dans l'encadré de gauche et que vous voulez mettre en indice ajoutez _ devant et un espace lorsqu'il sagit d'un exposant." 
                                        trigger={
                                            <Icon name="question circle" style={{backgroundColor : "transparent", padding : "0em 0em 0em 1em"}}/>
                                        }  
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={2}>Etat du produit</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3}>Le nb de mole initial du produit</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                lesproduits && lesproduits.map( (element, i) => {
                                    if(i === pageProduit){
                                    return (
                                        <Table.Row key={`createproduit${i}`}>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Input
                                                    style={{ width:"8em"}}
                                                    key={`produitstochiometrique{i}`} 
                                                    value={element.nbstochiometrique}
                                                    type="number"
                                                    onChange={(e,{value}) => changerNbStochiometriqueProduit(element.id, {value})}
                                                    onBlur={(e) => lostFocusNbStoechioProduit(element.id, element.nbstochiometrique)}
                                                    readOnly={disabledInput}
                                                    icon={disabledInput === true ? 'lock' : 'unlock'}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Input
                                                    style={{ width:"8em"}}
                                                    key={`produitformule{i}`} 
                                                    value={element.formule}
                                                    type="text"
                                                    maxLength="18"
                                                    error={element.formule === ""}
                                                    onChange={(e,{value}) => changerFormuleProduit(element.id, {value})}
                                                    readOnly={disabledInput}
                                                    icon={disabledInput === true ? 'lock' : 'unlock'}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <div
                                                    style={{ color : "rgba(0, 0, 0, 0.95)", backgroundColor : "rgb(255, 255, 255)" , width : "100%", outline: "0px solid", lineHeight: "17px", fontFamily : "fontFamily: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif", boxSizing : "borderBox", boxShadow : "rgba(0, 0, 0, 0) 0px 0px 0px 0", padding : "9.5px 14px", border : "1px", borderRadius : "4px", borderColor : "rgba(34, 36, 38, 0.15)", borderStyle : "solid"}}
                                                    dangerouslySetInnerHTML={{ __html: changerEcritureFormuleProduit(element.formule) }} >
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Select
                                                    style={{ width : "10em", margin : "auto"}}
                                                    fluid
                                                    options={options3}
                                                    value={element.etat}
                                                    error={element.etat === ""}
                                                    onChange={(e,{value}) => changerElementProduitEtat(element.id, {value})}
                                                    onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                                                    onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                                                    trigger={<span>{ element.etat === "s" ? "solide" : ( element.etat === "l" ? "liquide" : (element.etat === "g" ? "gaz" : "aqueux") )}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{textAlign : "center"}}>
                                                <Form.Input
                                                    style={{ width:"8em"}}
                                                    key={`produitni{i}`} 
                                                    value={element.nbmole}
                                                    type="number"
                                                    onChange={(e,{value}) => changerNbMoleProduit(element.id, {value})}
                                                    onBlur={(e) => lostFocusNbMoleProduit(element.id, element.nbmole)}
                                                    icon={'unlock'}
                                                />  
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                })
                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5'>
                                <Menu floated='right' pagination size="tiny">
                                    <Menu.Item as='a' icon onClick={ (e) => { pageProduit > 0 && setpageProduit(pageProduit-1) }}>
                                        <Icon name='chevron left' />
                                    </Menu.Item>
                                    {
                                        lesproduits && lesproduits.map( (element, i) => {
                                            return (
                                                <Menu.Item key={`createmenuproduit${i}`} onClick={(e) => setpageProduit(i)} active={pageProduit === i} as='a'>{i+1}</Menu.Item>
                                            )
                                        })
                                    }
                                    <Menu.Item as='a' icon onClick={ (e) => { pageProduit < lesproduits.length-1 && setpageProduit(pageProduit+1) }}>
                                        <Icon name='chevron right' />
                                    </Menu.Item>
                                </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>

            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={Annuler}>
                    Annuler
                </Button>
                <Button
                content="Modifier"
                labelPosition='right'
                icon='checkmark'
                onClick={Validate}
                positive
                />
            </Modal.Actions>
        </Modal>
         )
        }else{
            return (
                <Modal
                open={open}
                style={{overflowX : "auto"}}
            >
                <Modal.Content>
                    <Form>
                        <Form.Select
                            label="Choix de la réaction"
                            options={optionsReaction}
                            value={autreReaction}
                            onChange={(e,{value}) => handleReaction({value})}
                            onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                            onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                            trigger={<span>{autreReaction}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                        />
                        <Form.Input
                            label="Nom de la réaction"
                            value={nomReaction}
                            onChange={ (e) => setnomReaction(e.target.value)}
                            error={nomReaction === "" || listNoms.includes(nomReaction)}
                            maxLength="20"
                            onBlur={(e) => lostFocusNomReaction()}
                            icon={disabledInput === true ? 'lock' : 'unlock'}
                        />  
                        <Form.Select
                                fluid
                                label='Nombre de réactif'
                                options={options}
                                value={String(nbreactif)}
                                onChange={ (e, {value}) => handleNbReactif({value})}
                                onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                                onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                                trigger={<span>{nbreactif}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                        />
                        <Form.Select
                                fluid
                                label='Nombre de produits'
                                options={options2}
                                value={String(nbproduit)}
                                onChange={ (e, {value}) => handleNbProduit({value})}
                                onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                                onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                                trigger={<span>{nbproduit}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                        />
                    </Form>
    
                    <Table celled color='blue'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign='center'>
                                        <p>Le nb stœchio du réactif</p>
                                        <p>La formule du réactif
                                        <Popup 
                                            content="Losque vous entrez la formule dans l'encadré du haut et que vous voulez mettre en indice ajoutez _ devant et un espace lorsqu'il sagit d'un exposant." 
                                            trigger={
                                                <Icon name="question circle" style={{backgroundColor : "transparent", padding : "0em 0em 0em 1em"}}/>
                                            }  
                                            />
                                        </p>
                                        <p>Etat du réactif</p>
                                        <p>Le nb de mole initial du réactif</p>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
    
                            <Table.Body>
                            {
                                lesreactifs.map( (element, i) => {
                                    if(i === pageReactif){
                                    return (
                                        <Fragment key={`createreactif${i}`}>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Form.Input
                                                    key={`reactifstochiometrique{i}`} 
                                                    value={element.nbstochiometrique}
                                                    type="number"
                                                    onChange={(e,{value}) => changerNbStochiometriqueReactif(element.id, {value})}
                                                    onBlur={(e) => lostFocusNbStoechioReactif(element.id, element.nbstochiometrique)}
                                                    style={{ width: "100%"}}
                                                    icon={disabledInput === true ? 'lock' : 'unlock'}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={{ display : "flex", flexDirection : "column"}}>
                                                    <Form.Input
                                                        value={element.formule}
                                                        type="text"
                                                        maxLength="18"
                                                        error={element.formule === ""}
                                                        onChange={(e,{value}) => changerFormuleReactif(element.id, {value})}
                                                        style={{ width: "100%"}}
                                                        icon={disabledInput === true ? 'lock' : 'unlock'}
                                                    />           
                                                    <div
                                                        style={{ marginTop : "10px", color : "rgba(0, 0, 0, 0.95)", backgroundColor : "rgb(255, 255, 255)" , width : "100%", outline: "0px solid", lineHeight: "17px", fontFamily : "fontFamily: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif", boxSizing : "borderBox", boxShadow : "rgba(0, 0, 0, 0) 0px 0px 0px 0", padding : "9.5px 14px", border : "1px", borderRadius : "4px", borderColor : "rgba(34, 36, 38, 0.15)", borderStyle : "solid"}}
                                                        dangerouslySetInnerHTML={{ __html: changerEcritureFormuleReactif(element.formule) }} >
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Form.Select
                                                        fluid
                                                        options={options3}
                                                        value={element.etat}
                                                        error={element.etat === ""}
                                                        onChange={(e,{value}) => changerElementReactifEtat(element.id, {value})}
                                                        onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                                                        onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                                                        trigger={<span>{ element.etat === "s" ? "solide" : ( element.etat === "l" ? "liquide" : (element.etat === "g" ? "gaz" : "aqueux") )}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Form.Input
                                                        value={element.nbmole}
                                                        type="number"
                                                        onChange={(e,{value}) => changerNbMoleReactif(element.id, {value})}
                                                        onBlur={(e) => lostFocusNbMoleReactif(element.id, element.nbmole)}
                                                        style={{ width: "100%"}}
                                                        icon={'unlock'}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            </Fragment>
                                        )}
                                })
                            }
                            </Table.Body>
    
                            <Table.Footer>
                                <Table.Row style={{height : "60px", backgroundColor : "#F9FAFB", padding : "0px"}}>
                                    <Menu floated='right' pagination size="tiny" style={{margin : "11px"}}>
                                        <Menu.Item as='a' icon onClick={ (e) => { pageReactif > 0 && setpageReactif(pageReactif-1) }}>
                                            <Icon name='chevron left' />
                                        </Menu.Item>
                                        {
                                            lesreactifs.map( (element, i) => {
                                                return (
                                                    <Menu.Item key={`createmenureactif${i}`} onClick={(e) => setpageReactif(i)} active={pageReactif === i} as='a'>{i+1}</Menu.Item>
                                                )
                                            })
                                        }
                                        <Menu.Item as='a' icon onClick={ (e) => { pageReactif < lesreactifs.length-1 && setpageReactif(pageReactif+1) }}>
                                            <Icon name='chevron right' />
                                        </Menu.Item>
                                    </Menu>
                                </Table.Row>
                            </Table.Footer>
    
                        </Table>   
    
                         <Table celled color='red'>
    
                            <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign='center'>
                                            <p>Le nb stœchio du produit</p>
                                            <p>La formule du produit
                                            <Popup 
                                                content="Losque vous entrez la formule dans l'encadré du haut et que vous voulez mettre en indice ajoutez _ devant et un espace lorsqu'il sagit d'un exposant." 
                                                trigger={
                                                    <Icon name="question circle" style={{backgroundColor : "transparent", padding : "0em 0em 0em 1em"}}/>
                                                }  
                                                />
                                            </p>
                                            <p>Etat du produit</p>
                                            <p>Le nb de mole initial du produit</p>
                                        </Table.HeaderCell>
                                    </Table.Row>
                            </Table.Header>
    
                            <Table.Body>
                            {
                                lesproduits.map( (element, i) => {
                                    if(i === pageProduit){
                                    return (
                                        <Fragment  key={`createproduit${i}`}>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Form.Input
                                                    style={{ width:"100%"}}
                                                    value={element.nbstochiometrique}
                                                    type="number"
                                                    onChange={(e,{value}) => changerNbStochiometriqueProduit(element.id, {value})}
                                                    onBlur={(e) => lostFocusNbStoechioProduit(element.id, element.nbstochiometrique)}
                                                    icon={disabledInput === true ? 'lock' : 'unlock'}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={{ display : "flex", flexDirection : "column"}}>
                                                    <Form.Input
                                                        style={{ width:"100%"}}
                                                        key={`reactifformule{i}`} 
                                                        value={element.formule}
                                                        type="text"
                                                        maxLength="18"
                                                        error={element.formule === ""}
                                                        onChange={(e,{value}) => changerFormuleProduit(element.id, {value})}
                                                        icon={disabledInput === true ? 'lock' : 'unlock'}
                                                    />           
                                                    <div
                                                        style={{ marginTop : "10px", color : "rgba(0, 0, 0, 0.95)", backgroundColor : "rgb(255, 255, 255)" , width : "100%", outline: "0px solid", lineHeight: "17px", fontFamily : "fontFamily: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif", boxSizing : "borderBox", boxShadow : "rgba(0, 0, 0, 0) 0px 0px 0px 0", padding : "9.5px 14px", border : "1px", borderRadius : "4px", borderColor : "rgba(34, 36, 38, 0.15)", borderStyle : "solid"}}
                                                        dangerouslySetInnerHTML={{ __html: changerEcritureFormuleProduit(element.formule) }} >
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Form.Select
                                                        fluid
                                                        options={options3}
                                                        value={element.etat}
                                                        error={element.etat === ""}
                                                        onChange={(e,{value}) => changerElementProduitEtat(element.id, {value})}
                                                        onFocus={ (e) => e.target.children[0].children[0].style.color = "black"}
                                                        onBlur={ (e) => e.target.children[0].children[0].style.color = "rgb(118, 118, 118)"}
                                                        trigger={<span>{ element.etat === "s" ? "solide" : ( element.etat === "l" ? "liquide" : (element.etat === "g" ? "gaz" : "aqueux") )}<Icon style={{float : "right", color : "rgb(118, 118, 118)"}} name={disabledInput === true ? 'lock' : 'unlock'}/></span>}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={{textAlign : "center"}}>
                                                    <Form.Input
                                                        style={{ width:"100%"}}
                                                        key={`reactifni{i}`} 
                                                        value={element.nbmole}
                                                        type="number"
                                                        onChange={(e,{value}) => changerNbMoleProduit(element.id, {value})}
                                                        onBlur={(e) => lostFocusNbMoleProduit(element.id, element.nbmole)}
                                                        icon={'unlock'}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            </Fragment>
                                        )}
                                })
                            }
                            </Table.Body>
    
                            <Table.Footer>
                                <Table.Row style={{height : "60px", backgroundColor : "#F9FAFB", padding : "0px"}}>
                                    <Menu floated='right' pagination size="tiny" style={{margin : "11px"}}>
                                        <Menu.Item as='a' icon onClick={ (e) => { pageProduit > 0 && setpageProduit(pageProduit-1) }}>
                                            <Icon name='chevron left' />
                                        </Menu.Item>
                                        {
                                            lesproduits.map( (element, i) => {
                                                return (
                                                    <Menu.Item key={`createmenuproduit${i}`} onClick={(e) => setpageProduit(i)} active={pageProduit === i} as='a'>{i+1}</Menu.Item>
                                                )
                                            })
                                        }
                                        <Menu.Item as='a' icon onClick={ (e) => { pageProduit < lesproduits.length-1 && setpageProduit(pageProduit+1) }}>
                                            <Icon name='chevron right' />
                                        </Menu.Item>
                                    </Menu>
                                </Table.Row>
                            </Table.Footer>
                         </Table>          
    
                    </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={Annuler}>
                        Annuler
                    </Button>
                    <Button
                    content="Modifier"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={Validate}
                    positive
                    />
                </Modal.Actions>
            </Modal>
            )
        }
}
 
export default Edit;