import React, {useState, useEffect, Fragment} from 'react';
import { Segment } from 'semantic-ui-react';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

const Formule = ({reactionActuel, choixSelected,choix}) => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    useEffect(() => {

      window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    if(choix === "1"){
    return ( 
        <Segment style={{overflowX : "auto"}}>
        <div>
        {
            isMobile === false ? (
                <Fragment>
                    {
                        choixSelected === "1" ? (
                            <Fragment>
                                <p style={{ marginTop : "1em"}}>
                                    <BlockMath style={{textAlign : "left"}}>
                                    {
                                    `\\text{On a :} ~ \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}<\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}
                                    ~\\text{ car } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")}
                                    ~\\text{ et } ~ \\displaystyle \\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} `
                                    }
                                    </BlockMath>
                                </p>
                                <p style={{ marginTop : "1.5em"}}>
                                    <BlockMath>{`\\text{Donc le réactif limitant est A.}`}</BlockMath>
                                </p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <p style={{ marginTop : "1em"}}>
                                    <BlockMath>
                                    {
                                    `\\text{On a :} ~ \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}>\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}
                                    ~\\text{ car } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")}
                                    ~\\text{ et } ~ \\displaystyle \\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")}`
                                    }
                                    </BlockMath>
                                </p>
                                <p style={{ marginTop : "1.5em"}}>
                                   <BlockMath>{ `\\text{Donc le réactif limitant est B.}`}</BlockMath>
                                </p>
                            </Fragment>
                        )
                    }
                </Fragment>
            ) : (
                <Fragment>
                    {
                        choixSelected === "1" ? (
                            <Fragment>
                                <p style={{ marginTop : "1em"}}>
                                    <BlockMath>
                                        {
                                        `\\text{On a :} ~ \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}<\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}
                                        ~\\text{ car } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")}
                                        `
                                        }
                                    </BlockMath>
                                </p>
                                <p><BlockMath>{`\\text{Et } ~ \\displaystyle \\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")}`}</BlockMath></p>
                                <p style={{ marginTop : "1.5em"}}>
                                <BlockMath>{`\\text{Donc le réactif limitant est A.}`}</BlockMath>
                                </p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <p style={{ marginTop : "1em"}}>
                                    <BlockMath>
                                    {
                                    `\\text{On a :} ~ \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}>\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}
                                    ~\\text{ car } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")}
                                    `
                                    }
                                    </BlockMath>
                                </p>
                                <p><BlockMath>{`\\text{Et } ~ \\displaystyle \\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")}`}</BlockMath></p>
                                <p style={{ marginTop : "1.5em"}}>
                                <BlockMath>{`\\text{Donc le réactif limitant est B.}`}</BlockMath>
                                </p>
                            </Fragment>
                        )
                    }
                </Fragment>
            )
        }
        </div>
        </Segment>
     )

    }else{
        return ( 
            <Segment style={{overflowX : "auto"}}>
            <div>
            {
                isMobile === false ? (
                    <Fragment>
                        <p style={{ marginTop : "1em"}}>
                            <BlockMath>
                            {
                            `\\text{On a :} ~ \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}
                            ~\\text{ avec } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")}
                            ~\\text{ et } ~ \\displaystyle \\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")}`
                            }
                            </BlockMath>
                        </p>
                        <p style={{ marginTop : "1.5em"}}><BlockMath>{
                            `\\text{Les deux réactifs sont complétements consommés à la fin de la réaction.}`
                        }</BlockMath></p>        
                    </Fragment>
                ) : (
                    <Fragment>
                        <p style={{ marginTop : "1em"}}>
                            <BlockMath>
                            {
                            `\\text{On a :} ~ \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}`
                            }
                            </BlockMath>
                        </p>
                        <p>
                            <BlockMath>
                            {
                            `\\text{ avec } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")}
                            \\text{ et } ~ \\displaystyle \\frac{n(A)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")}`
                            }
                            </BlockMath>
                        </p>
                        <p style={{ marginTop : "1.5em"}}><BlockMath>{
                            `\\text{Les deux réactifs sont complétements consommés à la fin de la réaction.}`
                        }</BlockMath></p>
                    </Fragment>
                )
            }
            </div>
            </Segment>
         )
    
    }
}
 
export default Formule;