import React, { Fragment, useEffect,useState } from 'react';
import { Segment, Table,Popup } from 'semantic-ui-react';



const replaceWithChar = ["_20","_19","_18","_17","_16","_15","_14","_13","_12","_11","_10","_9","_8","_7","_6","_5","_4","_3","_2","_1"," -"," 2-"," 3-"," 4-"," +"," 2+"," 3+","4+","c","h","o","s","n","al","AL","aL","br","BR","bR","cl","cL","CL","i","NA","nA","na","k","fe","FE","fE","MN","mN","mn","cu","cU","CU","li","LI","lI","ba","bA","BA","mg","mG","MG","ZN","zN","zn","cr","CR","cR","cd","CD","cD","NI","ni","nI","sn","SN","sN","pb","pB","PB","HG","hg","hG","ag","aG","AG","pt","PT","pT","au","aU","AU"];

const replaceToHtml = ["<sub>20</sub>","<sub>19</sub>","<sub>18</sub>","<sub>17</sub>","<sub>16</sub>","<sub>15</sub>","<sub>14</sub>","<sub>13</sub>","<sub>12</sub>","<sub>11</sub>","<sub>10</sub>","<sub>9</sub>","<sub>8</sub>","<sub>7</sub>","<sub>6</sub>","<sub>5</sub>","<sub>4</sub>","<sub>3</sub>","<sub>2</sub>","<sub>1</sub>","<sup>-</sup>","<sup>2-</sup>","<sup>3-</sup>","<sup>4-</sup>","<sup>+</sup>","<sup>2+</sup>","<sup>3+</sup>","<sup>4+</sup>","C","H","O","S","N","Al","Al","Al","Br","Br","Br","Cl","Cl","Cl","I","Na","Na","Na","K","Fe","Fe","Fe","Mn","Mn","Mn","Cu","Cu","Cu","Li","Li","Li","Ba","Ba","Ba","Mg","Mg","Mg","Zn","Zn","Zn","Cr","Cr","Cr","Cd","Cd","Cd","Ni","Ni","Ni","Sn","Sn","Sn","Pb","Pb","Pb","Hg","Hg","Hg","Ag","Ag","Ag","Pt","Pt","Pt","Au","Au","Au"];


const Reaction = ({infos,avancement,xMax,pointActuel,nbPoints,indexReactifLimitant}) => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    function replaceMulCharInString(_string, charToReplace, replaceWith)
    {
        for (let i = 0; i < charToReplace.length; i++) {
            _string = _string.replaceAll(charToReplace[i], replaceWith[i]);
        }
        return _string;
    }
    
    function changerFormule(value){

        let charToReplace = [".","e-6","e-5","e-4","e-3","e-2","e-1","e+0","e+1","e+2","e+3","e+4","e+5","e+6"];
        let replaceWithChar = [",","×10⁻⁶","×10⁻⁵","×10⁻⁴","×10⁻³","×10⁻²","×10⁻¹","","×10¹","×10²","×10³","×10⁴","×10⁵","×10⁶"];
        let newString = replaceMulCharInString(value, charToReplace, replaceWithChar);
        
        return newString;
    }

    function changerEnHtml(value){

        let newString = replaceMulCharInString(value, replaceWithChar, replaceToHtml);
      
        return newString;
    }

    return ( 
        <Segment size="large" style={{padding : "0.5em 1em", overflowX : "auto"}}>
            <Table size="small" inverted verticalAlign='middle' unstackable singleLine>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}  textAlign='center' style={{padding: "0.5em"}}>Etat</Table.HeaderCell>
                    <Table.HeaderCell width={3} textAlign='center'>Avancement</Table.HeaderCell>
                    {
                            infos.reactifs.map( (elem,i) => {
                                if(i === 0){
                                    return (
                                        <Table.HeaderCell key={`reactif${i}`} width={2} textAlign='center'>
                                        { elem.nbstochiometrique > 1 ? (
                                            <div dangerouslySetInnerHTML={{ __html: `${elem.nbstochiometrique} ${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: `${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                        )}
                                        </Table.HeaderCell>
                                    )
                                }else{
                                    return (
                                        <Fragment key={`reactif${i}`}>
                                            <Table.HeaderCell key={`reactifp1${i}`} width={1} textAlign='center'>
                                            {`+`}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={`reactifp2${i}`} width={2} textAlign='center'>
                                            { elem.nbstochiometrique > 1 ? (
                                                <div dangerouslySetInnerHTML={{ __html: `${elem.nbstochiometrique} ${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: `${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                            )}
                                        </Table.HeaderCell>
                                        </Fragment>
                                    )
                                }
                            })
                        }

                        <Table.HeaderCell width={1} textAlign='center'>
                            {`=`}
                        </Table.HeaderCell>
                        {
                        infos.produits.map( (elem,i) => {
                                if(i === 0){
                                    return (
                                        <Table.HeaderCell key={`produit${i}`} width={2} textAlign='center'>
                                        { elem.nbstochiometrique > 1 ? (
                                                <div dangerouslySetInnerHTML={{ __html: `${elem.nbstochiometrique} ${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: `${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                            )}
                                            </Table.HeaderCell>
                                    )
                                }else{
                                    return (
                                        <Fragment>
                                            <Table.HeaderCell key={`produitp1${i}`} width={1} textAlign='center'>
                                            {`+`}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={`produitp2${i}`} width={2} textAlign='center'>
                                            { elem.nbstochiometrique > 1 ? (
                                                <div dangerouslySetInnerHTML={{ __html: `${elem.nbstochiometrique} ${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: `${changerEnHtml(elem.formule)} <sub>(${elem.etat})</sub>` }}></div>
                                            )}
                                            </Table.HeaderCell>
                                        </Fragment>
                                    )
                                }
                            })        
                        }
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign='center'>Initial</Table.Cell>
                        <Table.Cell textAlign='center'>x = 0</Table.Cell>
                        {
                            infos.reactifs.map( (elem,i) => {
                                if(i === 0){
                                    return (
                                        <Table.Cell width={2} textAlign='center' key={`reactif0${i}`}>
                                        {`${elem.nbmole} mol`}
                                        </Table.Cell>
                                    )
                                }else{
                                    return (
                                        <Fragment key={`reactif0${i}`}>
                                            <Table.Cell key={`reactif0p1${i}`} width={1} textAlign='center'>
                                            {``}
                                            </Table.Cell>
                                            <Table.Cell key={`reactif0p2${i}`} width={2} textAlign='center'>
                                            {`${elem.nbmole} mol`}
                                            </Table.Cell>
                                        </Fragment>
                                    )
                                }
                            })
                        }
                        <Table.Cell></Table.Cell>
                        {
                        infos.produits.map( (elem,i) => {
                                if(i === 0){
                                    return (
                                        <Table.Cell key={`produit2${i}`} width={2} textAlign='center'>
                                        {`${elem.nbmole} mol`}
                                        </Table.Cell>
                                    )
                                }else{
                                    return (
                                        <Fragment key={`produit2${i}`}>
                                            <Table.Cell key={`produitp12${i}`} width={1} textAlign='center'>
                                            {``}
                                            </Table.Cell>
                                            <Table.Cell key={`produitp22${i}`} width={2} textAlign='center'>
                                            {`${elem.nbmole} mol`}
                                            </Table.Cell>
                                        </Fragment>
                                    )
                                }
                            })        
                        }
                    </Table.Row>
                    {
                        pointActuel > 0 && (
                            <Table.Row>
                                <Fragment>
                                    <Table.Cell textAlign='center'>{pointActuel < nbPoints ? "En cours" : "Final"}</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                    { 
                                        pointActuel !== nbPoints ? (
                                            <div>{`x ≃ ${changerFormule(parseFloat(pointActuel*xMax/nbPoints).toExponential(2))}`.replace(".",",")}</div>
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: `x<sub>f</sub> ≃ ${changerFormule(parseFloat(pointActuel*xMax/nbPoints).toExponential(2)).replace(".",",")}`}}></div>
                                        )
                                    }
                                    </Table.Cell>
                                        {
                                                infos.reactifs.map( (elem,i) => {
                                                    if(i === 0){
                                                        return (
                                                            <Popup
                                                                key={`reactif3${i}`}
                                                                position='top center'
                                                                trigger={
                                                                    <Table.Cell width={2} textAlign='center'>
                                                                    { ( indexReactifLimitant === i && pointActuel === nbPoints ) ? `0,00 mol` :  `${changerFormule(String(parseFloat(parseFloat(elem.nbmole)-parseFloat(elem.nbstochiometrique)*pointActuel*xMax/nbPoints).toExponential(2)))} mol`}
                                                                    </Table.Cell>
                                                                }
                                                            >
                                                            <Popup.Content>
                                                                {
                                                                    pointActuel !== nbPoints ? (
                                                                        <div dangerouslySetInnerHTML={{ __html: elem.nbstochiometrique > 1 ?  ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - ${elem.nbstochiometrique}x` : ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - x`}} ></div>
                                                                    ) : (
                                                                        <div dangerouslySetInnerHTML={{ __html: elem.nbstochiometrique > 1 ?  ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - ${elem.nbstochiometrique}x<sub>f</sub>` : ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - x<sub>f</sub>`}} ></div>
                                                                    )
                                                                }
                                                            </Popup.Content>
                                                        </Popup>
                                                        )
                                                    }else{
                                                        return (
                                                            <Fragment key={`reactif3${i}`}>
                                                                <Table.Cell key={`reactif3p1${i}`} width={1} textAlign='center'>
                                                                {``}
                                                                </Table.Cell>
                                                                <Popup
                                                                position='top center'
                                                                trigger={
                                                                    <Table.Cell key={`reactif3${i}`} width={2} textAlign='center'>
                                                                    { ( indexReactifLimitant === i && pointActuel === nbPoints ) ? `0,00 mol` :  `${changerFormule(String(parseFloat(parseFloat(elem.nbmole)-parseFloat(elem.nbstochiometrique)*pointActuel*xMax/nbPoints).toExponential(2)))} mol`}
                                                                    </Table.Cell>
                                                                    }
                                                                >
                                                                    <Popup.Content>
                                                                    {
                                                                        pointActuel !== nbPoints ? (
                                                                            <div dangerouslySetInnerHTML={{ __html: elem.nbstochiometrique > 1 ?  ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - ${elem.nbstochiometrique}x` : ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - x`}} ></div>
                                                                        ) : (
                                                                            <div dangerouslySetInnerHTML={{ __html: elem.nbstochiometrique > 1 ?  ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - ${elem.nbstochiometrique}x<sub>f</sub>` : ` = n(${changerEnHtml(elem.formule)})<sub>i</sub> - x<sub>f</sub>`}} ></div>
                                                                        )
                                                                    }
                                                                    </Popup.Content>
                                                                </Popup>
                                                            </Fragment>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                infos.produits.map( (elem,i) => {
                                                    return (
                                                            <Fragment key={`produit3${i}`}>
                                                                <Table.Cell key={`produit3p1${i}`} width={1} textAlign='center'>
                                                                {``}
                                                                </Table.Cell>
                                                                <Popup
                                                                position='top center'
                                                                trigger={
                                                                    <Table.Cell key={`produit3${i}`} width={2} textAlign='center'>
                                                                    {`${changerFormule(String(parseFloat(parseFloat(elem.nbmole)+parseFloat(elem.nbstochiometrique)*pointActuel*xMax/nbPoints).toExponential(2)))} mol`}
                                                                    </Table.Cell>
                                                                    }
                                                                >
                                                                    <Popup.Content>
                                                                        {
                                                                            pointActuel !== nbPoints ? (
                                                                                <div dangerouslySetInnerHTML={{ __html: elem.nbstochiometrique > 1 ?  ` = ${elem.nbstochiometrique}x` : ` = x`}} ></div>
                                                                            ) : (
                                                                                <div dangerouslySetInnerHTML={{ __html: elem.nbstochiometrique > 1 ?  ` = ${elem.nbstochiometrique}x<sub>f</sub>` : ` = x<sub>f</sub>`}} ></div>
                                                                            )
                                                                        }
                                                                    </Popup.Content>
                                                                </Popup>
                                                            </Fragment>
                                                        )
                                                })
                                            }
                                    </Fragment>
                            </Table.Row>
                        )
                    }
                    
                </Table.Body>
            </Table>
        </Segment>
     );
}
 
export default Reaction;