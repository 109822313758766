import React, { Component, Fragment } from 'react';
import { Container, Message, Segment } from 'semantic-ui-react';
import About2 from './About2.js';
import Graph2 from './Graph2.js';
import MenuAvancement2 from './MenuAvancement2.js';
import MenuHaut2 from './MenuHaut2';
import SousMenu from './SousMenu.js';
import shortid from  "shortid";
import Reaction2 from './Reaction2.js';
import Formule from './Formule.js';
import Formule2 from './Formule2.js';
import Formule3 from './Formule3.js';


class App2 extends Component {
    
    constructor(props) {
    super(props);
    this.state = {
      isMobile : false,
      choix : "1",
      choix2 : "1",
      choixSelected : "1",
      about : false,
      show : false,
      xMax : 1,
      actif : "1",
      reactionActuel : null,
      nbPoints : 20,
      pointActuel : 0,
      indexReactifLimitant : 0,
    }
    this.ModifierPoint = this.ModifierPoint.bind(this);
    this.onStart = this.onStart.bind(this);
    this.updateNbSto = this.updateNbSto.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  handleWindowSizeChange() {
    if(window.innerWidth <= 748){
      this.setState({
        isMobile : true,
      });
    }else{
      this.setState({
        isMobile : false,
      });
    }
}

  async componentDidMount(){

    window.addEventListener('resize', this.handleWindowSizeChange);

    await this.setState({
      choix : localStorage.getItem('choix') || "1",
      choix2 : localStorage.getItem('choix2') || "1",
      choixSelected : localStorage.getItem('choixSelected') || "1",
      openEdit : false,
      create : false,
      pointActuel : 0,
    })

    if(localStorage.getItem('reactionActuel2') !== null){
      await this.setState({
        reactionActuel : JSON.parse(localStorage.getItem('reactionActuel2'))
      });
      console.log(localStorage.getItem('reactionActuel2'));
      this.setState({
        actif : this.state.reactionActuel.name,
      });
    }else{
      await this.setState({
        reactionActuel : {
          name : "1",
          reactifs : [
            {
              formule : "A",
              nbstoechiometrique : 1,
              nbmole : 2,
              id : `reactif${shortid.generate()}`,
            },
            {
              formule : "B",
              nbstoechiometrique : 1,
              nbmole : 2,
              id : `reactif${shortid.generate()}`,
            },
          ],
          produits : [
            {
              formule : "C",
              nbstoechiometrique : 1,
              nbmole : 0,
              id : `produit${shortid.generate()}`,
            }
          ]
        },
      });
      console.log(localStorage.getItem('reactionActuel2'));
    }
 
    if(this.state.reactionActuel !== null){
        
      // on trouve le récactif limitant

      let lesx = [];

      this.state.reactionActuel.reactifs.map( elem => {
          lesx.push(parseFloat(parseFloat(elem.nbmole)/parseInt(elem.nbstoechiometrique)));
      });
      
      let min = 10000000000;
      let indexmin = 100000000;

      lesx.map( (elem,index) => {
        if(elem < min){
          min = elem;
          indexmin = index;
        }
      })

      this.setState({
          indexReactifLimitant : indexmin,
          xMax : min,
      })
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  ModifierPoint = async (x) => {
    await this.setState({
      pointActuel : x,
    });
    localStorage.setItem('pointActuel2', this.state.pointActuel);
  }

  onStart = async ()=>{
    console.log("running");
    if(this.state.pointActuel < this.state.nbPoints){
      await this.setState({
        pointActuel : this.state.pointActuel+1
      }); 
      localStorage.setItem('pointActuel2', this.state.pointActuel);
    }else{
      clearInterval(this.f);
    }
  }

  Play = (run) => {
    if(this.state.pointActuel < this.state.nbPoints && run === true){
      this.f=setInterval(this.onStart,500);
    }else{
      clearInterval(this.f);
    }
  }

  changerNbPoints = (nb) => {
    this.setState({
        nbPoints : nb,
    })
  }

  openAbout = (value) => {
    this.setState({
        about : value,
    })
  }

  updateNbSto = async (value) => {
      await this.setState({
        reactionActuel : { ...value }
      });

      let lesx = [];

      this.state.reactionActuel.reactifs.map( elem => {
          lesx.push(parseFloat(parseFloat(elem.nbmole)/parseInt(elem.nbstoechiometrique)));
      });
      
      let min = 10000000000;
      let indexmin = 100000000;

      lesx.map( (elem,index) => {
        if(elem < min){
          min = elem;
          indexmin = index;
        }
      })

      this.setState({
          indexReactifLimitant : indexmin,
          xMax : min,
      });

      localStorage.setItem('reactionActuel2', JSON.stringify(this.state.reactionActuel));
  }

  updateChoix = (value) => {
    this.setState({
      choix : value,
    });
    localStorage.setItem('choix', value);
  }

  updateChoix2 = async (value) => {
    this.setState({
      choix2 : value,
    });
    localStorage.setItem('choix2', value);

    let lareaction = {};

    if(value === "1"){

      lareaction = {
              name : "1",
              reactifs : [
                {
                  formule : "A",
                  nbstoechiometrique : this.state.reactionActuel.reactifs[0].nbstoechiometrique,
                  nbmole : 2,
                  id : `reactif${shortid.generate()}`,
                },
                {
                  formule : "B",
                  nbstoechiometrique : this.state.reactionActuel.reactifs[1].nbstoechiometrique,
                  nbmole : 2,
                  id : `reactif${shortid.generate()}`,
                },
              ],
              produits : [
                {
                  formule : "C",
                  nbstoechiometrique : this.state.reactionActuel.produits[0].nbstoechiometrique,
                  nbmole : 0,
                  id : `produit${shortid.generate()}`,
                },
              ]
            };
      }else if(value === "2"){
          
        lareaction = {
                name : "2",
                reactifs : [
                  {
                    formule : "A",
                    nbstoechiometrique : this.state.reactionActuel.reactifs[0].nbstoechiometrique,
                    nbmole : 2,
                    id : `reactif${shortid.generate()}`,
                  },
                  {
                    formule : "B",
                    nbstoechiometrique : this.state.reactionActuel.reactifs[1].nbstoechiometrique,
                    nbmole : 2,
                    id : `reactif${shortid.generate()}`,
                  },
                ],
                produits : [
                  {
                    formule : "C",
                    nbstoechiometrique : this.state.reactionActuel.produits[0].nbstoechiometrique,
                    nbmole : 0,
                    id : `produit${shortid.generate()}`,
                  },
                  {
                    formule : "D",
                    nbstoechiometrique : "1",
                    nbmole : 0,
                    id : `produit${shortid.generate()}`,
                  },
                ]
              };
          }
  
        await this.setState({
            reactionActuel : lareaction,
        });

        localStorage.setItem('reactionActuel2', JSON.stringify(this.state.reactionActuel));

        let lesx = [];

        this.state.reactionActuel.reactifs.map( elem => {
          lesx.push(parseFloat(parseFloat(elem.nbmole)/parseInt(elem.nbstoechiometrique)));
        });
  
        let min = 10000000000;
        let indexmin = 100000000;
  
        lesx.map( (elem,index) => {
          if(elem < min){
            min = elem;
            indexmin = index;
          }
        })
  
        this.setState({
            indexReactifLimitant : indexmin,
            xMax : min,
        });

        localStorage.setItem('indexReactifLimitant2', indexmin);
        localStorage.setItem('xmax2', min);
  
  }

  updateChoixSelected = (value) => {
    this.setState({
      choixSelected : value,
    });
    localStorage.setItem('choixSelected', value);
  }

  render(){
    return (
      <Fragment>
        <About2 show={this.state.about} openAbout={this.openAbout}/>
        <MenuHaut2 openAbout={this.openAbout}/>
        <div style={{display: "flex", flexDirection: "column"}}>
        {
          this.state.reactionActuel !== null && (
            <Fragment>
                <SousMenu isMobile={this.state.isMobile} updateChoix={this.updateChoix} updateChoix2={this.updateChoix2}/>
                <Graph2 updateChoixSelected={this.updateChoixSelected} visible={this.state.choix === "1"} changerNbPoints={this.changerNbPoints} pointActuel={this.state.pointActuel} nbPoints={this.state.nbPoints} xMax={this.state.xMax} reactionActuel={this.state.reactionActuel}/>
                <Reaction2 isMobile={this.state.isMobile} choixSelected={this.state.choixSelected} choix={this.state.choix} choix2={this.state.choix2} update={this.updateNbSto} indexReactifLimitant={this.state.indexReactifLimitant} pointActuel={this.state.pointActuel} xMax={this.state.xMax} nbPoints={this.state.nbPoints} infos={this.state.reactionActuel}/>
                <Container style={{marginTop : "0.5em"}}>
                    <MenuAvancement2 xMax={this.state.xMax} pointActuel={this.state.pointActuel} nbPoints={this.state.nbPoints} ModifierPoint={this.ModifierPoint} Play={this.Play} run={this.state.pointActuel+1 <= this.state.nbPoints}/>       
                    <Formule reactionActuel={this.state.reactionActuel} choixSelected={this.state.choixSelected} choix={this.state.choix}/>
                    <Formule2 choixSelected={this.state.choixSelected} reactionActuel={this.state.reactionActuel} choix={this.state.choix}/>
                    <Formule3 choixSelected={this.state.choixSelected} reactionActuel={this.state.reactionActuel} choix={this.state.choix}/>
                </Container>
            </Fragment>
          )
        }
        </div>       
        </Fragment>
         );
    }
}
 
export default App2;