import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const MenuHaut = ({ listReactions,act,setopen,setReactionActuel,commande,setopenEdit,openAbout}) => {

    const [actif, setactif] = useState(null);
    const [activeItem, setactiveItem] = useState(null);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setactif(act);

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    useEffect(() => {
        setactif(act);
      }, [act,listReactions])
    
    function add(){
        setopen(true);
    }

    function addActif({name},element){
        setactif(name);
        setReactionActuel(element);
    }

    const navigate = useNavigate()

    const faireRedirection = () =>{ 
        let url = "/";
        navigate(url);
    }

    return ( 
        <Fragment>
            <Menu inverted attached size="mini">
                {
                    listReactions.map( (element,index) => {
                        if(isMobile === false){
                            return (
                                <Menu.Item
                                    key={element.id}
                                    name={element.name}
                                    active={actif === element.name}
                                    onClick={(e, {name}) => addActif({name},element)}
                                    color={"red"}
                                    style={{ fontWeight : "bold",fontSize: "1.2em", }}
                                >
                                    {element.name}
                                </Menu.Item>
                            )
                        }else{
                            return (
                                <Menu.Item
                                    key={element.id}
                                    name={element.name}
                                    active={actif === element.name}
                                    onClick={(e, {name}) => addActif({name},element)}
                                    color={"red"}
                                    style={{ fontWeight : "bold",fontSize: "1.2em", }}
                                >
                                    {index+1}
                                </Menu.Item>
                            )
                        }                   
                    })
                }
                {
                    listReactions.length < 4 && (
                        <Menu.Item>
                            <Button primary 
                            icon="add" 
                            style={{ padding : "5px"}}
                            onClick={add}
                            />
                        </Menu.Item>
                    )
                }
                <Menu.Menu position='right'>
                    <Button 
                        circular  
                        onClick={faireRedirection}
                        style={{ 
                                padding : "5px", 
                                backgroundColor : "transparent",
                                verticalAlign: "middle"
                        }} 
                        primary
                        >
                        <Icon name="home"/>
                    </Button>
                    <Button 
                        circular  
                        style={{ padding : "5px", 
                        backgroundColor : "transparent"
                        }} 
                        primary
                        onClick={ (e) => openAbout(true)}
                        >
                        <Icon name="question circle outline"/>
                    </Button>
                </Menu.Menu>
            </Menu>
            {
                listReactions.length !== 0 && (
                    <Menu icon floated='right' style={{margin: "10px"}} size="tiny">
                        <Menu.Item
                            name='edit'
                            active={activeItem === 'edit'}
                            onClick={() => setopenEdit(true)}
                            >
                            <Icon name='edit' />
                        </Menu.Item>
                        <Menu.Item
                            name='trash alternate'
                            active={activeItem === 'trash alternate'}
                            onClick={() => commande(false)}
                            >
                            <Icon name='trash alternate' />
                        </Menu.Item>
                    </Menu>
                )
            }
            
        </Fragment>
     );
}
 
export default MenuHaut;