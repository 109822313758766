import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Button, Icon,Card, Image } from 'semantic-ui-react';

const MenuApp = ({openAbout}) => {

    const [actif, setactif] = useState(null);
    const [activeItem, setactiveItem] = useState(null);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return ( 
        <Menu inverted attached size="mini" style={{width : "100%"}}>
                
                <Menu.Menu position='right'>
                    <Button 
                        circular  
                        style={{ padding : "5px", 
                        backgroundColor : "transparent"
                        }} 
                        primary
                        onClick={ (e) => openAbout(true)}
                        >
                        <Icon name="question circle outline"/>
                    </Button>
                </Menu.Menu>
            </Menu>
     );
}
 
export default MenuApp;