import React,{useState, useEffect} from 'react';
import { Modal, Button, Icon, Header } from 'semantic-ui-react';

const About = ({show, openAbout}) => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(show);
      }, [show])

    function Close(){
        setOpen(false);
        openAbout(false);
    }

    return ( 
        <Modal
        basic
        open={open}
        size='small'
        >
            <Header icon>
                <Icon name='question circle' />
                A propos de l'application web
            </Header>
            <Modal.Content style={{ size : "2em"}}>
                <p>
                    Auteur : SAMUEL JACQUINET
                </p>
                <p>
                    Version : 1.0.8
                </p>
                <p>
                    Copyright : Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International (CC BY-NC-ND 4.0)
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' inverted onClick={() => Close()}>
                <Icon name='remove' /> Fermer
                </Button>
            </Modal.Actions>
        </Modal>
     );
}
 
export default About;