import React, {useState, useEffect} from 'react';
import { Segment, Menu, Icon, Dropdown, Container } from 'semantic-ui-react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar, Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const colors = ['rgba(255, 99, 132, 0.7)',
                'rgba(54, 162, 235, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(75, 192, 192, 0.7)',
                'rgba(153, 102, 255, 0.7)',
                'rgba(255, 159, 64, 0.7)'];

const lettres = ["A","B","C","D","E","F","G"]

const Graph2 = ({reactionActuel,nbPoints, xMax,changerNbPoints,pointActuel,visible,updateChoixSelected}) => {

    const [activeItem, setactiveItem] = useState("chart bar")

    const [label, setlabel] = useState([])

    const [label2, setlabel2] = useState([])

    const [label3, setlabel3] = useState([])

    const [mole, setmole] = useState([])

    const [listReactifs, setlistReactifs] = useState([])

    const [listProduits, setlistProduits] = useState([])

    const [nombrePoints, setnombrePoints] = useState('20')

    const [nMax, setnMax] = useState(0)

    const [options,setoptions] = useState([])

    const [choixSelected, setchoixSelected] = useState("1")

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {

        let largeur = document.getElementById("labox").clientWidth;

        if(width < 800){
            setWidth(largeur-20);
        }

    }

    useEffect(() => {
      if(localStorage.getItem('activeGraph2') !== null){
          setactiveItem(localStorage.getItem('activeGraph2'));
      }

      if(localStorage.getItem('choixSelected') !== null){
        setchoixSelected(localStorage.getItem('choixSelected'));
      }

      if(localStorage.getItem('choixLimitant') !== null){
          setoptions(JSON.parse(localStorage.getItem('choixLimitant')));
      }else{
                let i =0;
                let opt = [];
                while(i < reactionActuel.reactifs.length){
                    opt.push({ 
                        key: String(i+1), 
                        text: `${lettres[i]} est le réactif limitant`, 
                        value: String(i+1),
                    })
                    i++;
                }

            setoptions(opt);
      }

      window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    useEffect(() => {

      let leslabels = [];
      let leslabels2 = [];
      let lesmoles = [];    

      let leslabels3 = [];

      setlistReactifs( reactionActuel.reactifs.map( elem => []) );
      setlistProduits( reactionActuel.produits.map( elem => []) );

      let indLabel = 0;

        while(indLabel <= pointActuel){
            leslabels2.push(String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            leslabels3.push("x = " + String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            indLabel += 1;
        }

      let lesreactifs = [...listReactifs];

      reactionActuel.reactifs.map( (elem,index) => {

        leslabels.push(elem.formule);

        lesmoles.push(parseFloat(elem.nbmole)-parseFloat(elem.nbstoechiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesreactifs[index] = [];

        let ind = 0;

        while(ind <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbmole)-parseFloat(elem.nbstoechiometrique)*parseFloat(xMax*ind/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesreactifs[index].push(valeurMoleBis);
            }         
            ind += 1;
        }

      });

      setlistReactifs(lesreactifs);

      let lesproduits = [...listProduits];

      reactionActuel.produits.map( (elem,index) => {

        leslabels.push(elem.formule);

        lesmoles.push(parseFloat(elem.nbstoechiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesproduits[index] = [];

        let indProd = 0;

        while(indProd <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbstoechiometrique)*parseFloat(xMax*indProd/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesproduits[index].push(valeurMoleBis);
            }        
            indProd += 1;
        }

      })

      setlistProduits(lesproduits);

      setlabel(leslabels);
      setlabel2(leslabels2);
      setlabel3(leslabels3);
   
      setmole(lesmoles);

      let a = 0;
      let mmax = 0;

      while(a < reactionActuel.produits.length){
            let vale = reactionActuel.produits[a].nbstoechiometrique*xMax;
            if(vale > mmax){
                mmax = vale;
            }
            a++;
      }

      let b = 0;

      while(b < reactionActuel.reactifs.length){
        let vale = reactionActuel.reactifs[b].nbmole;
        if(vale > mmax){
            mmax = vale;
        }
        b++;
      }

      setnMax(mmax);

    }, [reactionActuel])


    useEffect(() => {

      setnombrePoints(String(nbPoints));

      let lesmoles = [];

      let leslabels2 = [];

      let leslabels3 = [];
   
      let indLabel = 0;

        while(indLabel <= pointActuel){
            leslabels2.push(String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            leslabels3.push("x = " + String(changerFormule((xMax*indLabel/nbPoints).toExponential(2))).replace(".",","));
            indLabel += 1;
        }

      let lesreactifs = [...listReactifs];

      reactionActuel.reactifs.map( (elem,index) => {

        lesmoles.push(parseFloat(elem.nbmole)-parseFloat(elem.nbstoechiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesreactifs[index] = [];

        let ind = 0;

        while(ind <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbmole)-parseFloat(elem.nbstoechiometrique)*parseFloat(xMax*ind/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesreactifs[index].push(valeurMoleBis);
            }         
            ind += 1;
        }

      });

      setlistReactifs(lesreactifs);

      let lesproduits = [...listProduits];

      reactionActuel.produits.map( (elem,index) => {

        lesmoles.push(parseFloat(elem.nbstoechiometrique)*parseFloat(pointActuel*xMax/nbPoints));

        lesproduits[index] = [];

        let indProd = 0;

        while(indProd <= pointActuel){

            let valeurMoleBis = (parseFloat(elem.nbstoechiometrique)*parseFloat(xMax*indProd/nbPoints)).toExponential(2);
            if(valeurMoleBis >= 0){
                lesproduits[index].push(valeurMoleBis);
            }         
            indProd += 1;
        }

      })

      setlistProduits(lesproduits);
      setmole(lesmoles);

      setlabel2(leslabels2);

      setlabel3(leslabels3);

      let a = 0;
      let mmax = 0;

      while(a < reactionActuel.produits.length){
            let vale = reactionActuel.produits[a].nbstoechiometrique*xMax;
            if(vale > mmax){
                mmax = vale;
            }
            a++;
      }

      let b = 0;

      while(b < reactionActuel.reactifs.length){
        let vale = reactionActuel.reactifs[b].nbmole;
        if(vale > mmax){
            mmax = vale;
        }
        b++;
      }

      setnMax(mmax);

    }, [pointActuel,nbPoints,xMax,reactionActuel])

    function change(e,{name}){
        setactiveItem(name);
        localStorage.setItem('activeGraph', name)
    }

    function handleNbPoints(e,data){
        setnombrePoints(data.value);
        changerNbPoints(data.value);
    }

    function changerFormule(value){

        let charToReplace = [".","e-6","e-5","e-4","e-3","e-2","e-1","e+0","e+1","e+2","e+3","e+4","e+5","e+6"];
        let replaceWithChar = [",","×10⁻⁶","×10⁻⁵","×10⁻⁴","×10⁻³","×10⁻²","×10⁻¹","","×10¹","×10²","×10³","×10⁴","×10⁵","×10⁶"];
        let newString = replaceMulCharInString(value, charToReplace, replaceWithChar);
        
        return newString;
    }

    function replaceMulCharInString(_string, charToReplace, replaceWith)
    {
        for (let i = 0; i < charToReplace.length; i++) {
            _string = _string.replaceAll(charToReplace[i], replaceWith[i]);
        }
        return _string;
    }

    function handleChangeSelected(e,data){
        setchoixSelected(data.value);
        updateChoixSelected(data.value);
    }

    return ( 
        <Container id="labox" style={{display : "flex", flexDirection : "column", alignItems : "stretch", padding : "0.5em 0em", marginTop : "0.3em" }}>
            <div style={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between"}}>
                        <Menu icon compact>
                            <Menu.Item
                                name='chart bar'
                                active={activeItem === 'chart bar'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='chart bar' />
                            </Menu.Item>
                            <Menu.Item
                                name='chart line'
                                active={activeItem === 'chart line'}
                                onClick={(e,{name}) => change(e,{name})}
                                >
                                <Icon name='chart line' />
                            </Menu.Item>
                        </Menu>
                        {
                            visible === true && (
                                <Menu compact>
                                    <Dropdown          
                                        selection
                                        compact
                                        value={choixSelected} 
                                        options={options}
                                        onChange={(e,data) => handleChangeSelected(e,data)}
                                    />
                                </Menu>
                            )
                        }
            </div>
                
                     
            <div style={{position: "relative" , margin: "1em auto", padding: "0em", fontSize: "30", width: "80%"}}>
            {
                activeItem === "chart bar" && reactionActuel !== null ? (
                    <Bar
                    data={{
                        labels: label,
                        datasets: [
                            {
                                backgroundColor: reactionActuel.reactifs.map( elem => 'rgba(53, 162, 235, 0.7)').concat(reactionActuel.produits.map( elem => 'rgba(255, 99, 132, 0.7)')),
                                data: mole
                            }
                        ]}}
                    options={{
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return String(changerFormule(context.parsed.y.toExponential(2))).replace(".",",") + ' mol';
                                    }
                                }
                            }
                        },
                        scales: {
                            y: {
                                max: nMax,
                                min: 0,
                                ticks: {
                                    callback: function(value, index, ticks) {
                                        return String(value).replace(".",",") + " mol";
                                    }
                                }
                            }
                        },
                        title:{
                            display:false,
                            text:'',
                            fontSize:40,
                            fontWeight: "bold"
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: 30,
                                    weight: "bold"
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        responsive : true,
                    }}
                    width={500} 
                    height={200}
                    />
                ) : (
                    <Line
                    id="laligne"
                    height={200}
                    data={{
                        labels: label2,
                        datasets: reactionActuel.reactifs.map( (elem,i) => ({
                            label : elem.formule,
                            backgroundColor : colors[i],
                            borderColor : colors[i],
                            data : listReactifs[i],
                            labels : label3[i],
                        })).concat(reactionActuel.produits.map( (elem,i) => ({
                            label : elem.formule,
                            backgroundColor : colors[i+reactionActuel.reactifs.length],
                            borderColor : colors[i+reactionActuel.reactifs.length],
                            data : listProduits[i],
                            labels : label3[i+reactionActuel.reactifs.length],
                        })))
                    }}
                    options={{
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return String(changerFormule(context.parsed.y.toExponential(2))).replace(".",",") + ' mol';
                                    },
                                    title: function(context) {
                                        let title = context[0].label;
                                        return "x = " + title;
                                    }
                                }
                            }
                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: function(value, index, ticks) {
                                        return String(value).replace(".",",") + " mol";
                                    }
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        responsive : true,
                        title:{
                            display:false,
                            text:'',
                            fontSize:40,
                            fontWeight: "bold"
                        },
                        animation: true,
                    }}
                    />
                )
            }
            
            </div>
        </Container>
     );
}
 
export default Graph2;