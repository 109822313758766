import React, { Fragment, Component } from 'react';
import { Container, Message } from 'semantic-ui-react';
import About from './About';
import Create from './Create';
import Delete from './Delete';
import Edit from './Edit';
import Graph from './Graph';
import MenuAvancement from './MenuAvancement';
import MenuHaut from './MenuHaut';
import Reaction from './Reaction';

class App1 extends Component {

  constructor(props){
    super(props);
    this.state = {
      about : false,
      vitesse : 1,
      show : false,
      openEdit: false,
      xMax : 1,
      lesReactions : [],
      actif : null,
      create : false,
      reactionActuel : null,
      nbPoints : 20,
      pointActuel : 0,
      listNoms : [],
      indexReactifLimitant : 0,
    }
    this.addReaction = this.addReaction.bind(this);
    this.editReaction = this.editReaction.bind(this);
    this.setReactionActuel = this.setReactionActuel.bind(this);
    this.ModifierPoint = this.ModifierPoint.bind(this);
    this.onStart = this.onStart.bind(this);
  }

  async componentDidMount(){

    await this.setState({
      lesReactions : JSON.parse(localStorage.getItem('lesReactions')) || [],
      listNoms : JSON.parse(localStorage.getItem('listNoms')) || [],
      reactionActuel : JSON.parse(localStorage.getItem('reactionActuel')) || null,
      openEdit : false,
      create : false,
      pointActuel : 0,
    })

    if(localStorage.getItem('reactionActuel') !== null){
      this.setState({
        actif : this.state.reactionActuel.name,
      })
    }

    if(localStorage.getItem('actif') !== null){
      this.setState({
        actif : this.state.actif || null
      })
    }

    if(localStorage.getItem('listNoms') !== null){
      this.setState({
        listNoms : JSON.parse(localStorage.getItem('listNoms')) || [],
      })
    }
 
    if(localStorage.getItem('reactionActuel') !== null){
        
      // on trouve le récactif limitant

      let lesx = [];

      this.state.reactionActuel.reactifs.map( elem => {
          lesx.push(parseFloat(parseFloat(elem.nbmole)/parseInt(elem.nbstochiometrique)));
      });
      
      let min = 10000000000;
      let indexmin = 100000000;

      lesx.map( (elem,index) => {
        if(elem < min){
          min = elem;
          indexmin = index;
        }
      })

      this.setState({
          indexReactifLimitant : indexmin,
          xMax : min,
      })
    }
  }

  componentDidUpdate(prevProps, prevState){
    
    if(prevState.reactionActuel !== this.state.reactionActuel && this.state.reactionActuel !== null){

      // on trouve le récactif limitant

      let lesx = [];

      this.state.reactionActuel.reactifs.map( elem => {
        lesx.push(parseFloat(parseFloat(elem.nbmole)/parseInt(elem.nbstochiometrique)));
      });

      let min = 10000000000;
      let indexmin = 100000000;

      lesx.map( (elem,index) => {
        if(elem < min){
          min = elem;
          indexmin = index;
        }
      })

      this.setState({
          indexReactifLimitant : indexmin,
          xMax : min,
      })

      localStorage.setItem('reactionActuel', JSON.stringify(this.state.reactionActuel));

    }
  }

  handleOpen = () => {
    this.setState({
      create : true,
    })
    localStorage.setItem('create', this.state.create);
  }

  handleClose = () => {
    this.setState({
      create : false,
    })
    localStorage.setItem('create', this.state.create);
  }

  addReaction = async (reaction) => {
    await this.setState({
      lesReactions : [...this.state.lesReactions, reaction],
      reactionActuel : reaction,
      actif : reaction.name,
      listNoms : [...this.state.listNoms, reaction.name],
    });

    localStorage.setItem('lesReactions', JSON.stringify(this.state.lesReactions));
    localStorage.setItem('reactionActuel', JSON.stringify(this.state.reactionActuel));
    localStorage.setItem('listNoms', JSON.stringify(this.state.listNoms));
  }

  setReactionActuel = (element) => {
      this.setState({
        reactionActuel : element,
        actif : element.name,
      })
  }

  ModifierPoint = async (x) => {
    await this.setState({
      pointActuel : x,
    });
    localStorage.setItem('pointActuel', this.state.pointActuel);
  }

  onStart = async ()=>{
    if(this.state.pointActuel < this.state.nbPoints){
      await this.setState({
        pointActuel : this.state.pointActuel+1
      }); 
      localStorage.setItem('pointActuel', this.state.pointActuel);
    }else{
      clearInterval(this.f);
    }
  }

  Play = (run) => {
    if(this.state.pointActuel < this.state.nbPoints && run === true){
      this.f=setInterval(this.onStart,500);
    }else{
      clearInterval(this.f);
    }
  }

  handleDelete = async (del) => {
    await this.setState({
      show : !this.state.show,
    })

    if(del === true){
        let lesreactions = [...this.state.lesReactions];

        let name = this.state.reactionActuel.name;

        let index = lesreactions.findIndex( elem => elem.id === this.state.reactionActuel.id );

        let result = lesreactions.filter( elem => elem.id !== this.state.reactionActuel.id );

        if(this.state.lesReactions.length === 1){

          await this.setState({
            lesReactions : result,
            listNoms : this.state.listNoms.filter( elem => elem !== name )
          });

          await this.setState({
            reactionActuel : null,
            actif : null,
          });

        }else{

          await this.setState({
            lesReactions : result,
            listNoms : this.state.listNoms.filter( elem => elem !== name )
          });
          
          await this.setState({
            reactionActuel : this.state.lesReactions[0],
            actif : this.state.lesReactions[0].name
          });

        }

        localStorage.setItem('lesReactions', JSON.stringify(this.state.lesReactions));
        localStorage.setItem('reactionActuel', JSON.stringify(this.state.reactionActuel));
        localStorage.setItem('listNoms', JSON.stringify(this.state.listNoms));
    }
  }

  handleEdit = (value) => {
    this.setState({
      openEdit : value,
      listNoms : this.state.listNoms.filter( elem => elem !== this.state.reactionActuel.name)
    })

    localStorage.setItem('openEdit', value);

    console.log("openEdit");
  }

  handleCloseEdit = (value) => {

    this.setState({
      openEdit : value,
    })

    console.log("CloseEdit");
  }

  editReaction = async (lareaction) => {

    let lesreactions = [...this.state.lesReactions];

    let reactionReste = lesreactions.filter( elem => elem.id !== lareaction.id);

    //console.log(lareaction);

    await this.setState({
      lesReactions : [...reactionReste, lareaction],
      actif : lareaction.name,
      reactionActuel : lareaction,
      listNoms : [...this.state.listNoms, lareaction.name]
    });

    console.log("edition");

    localStorage.setItem('lesReactions', JSON.stringify(this.state.lesReactions));
    localStorage.setItem('reactionActuel', JSON.stringify(this.state.reactionActuel));
    localStorage.setItem('listNoms', JSON.stringify(this.state.listNoms));
    localStorage.setItem('actif', this.state.actif);
  }

  changerNbPoints = (nb) => {
    this.setState({
        nbPoints : nb,
    })
  }

  openAbout = (value) => {
    this.setState({
        about : value,
    })
  }

  noEditReaction = async (lareaction) => {

    await this.setState({
      listNoms : [...this.state.listNoms, lareaction.name]
    });

    localStorage.setItem('listNoms', JSON.stringify(this.state.listNoms));

  }

  render(){
    return (
      <Fragment>
        <About show={this.state.about} openAbout={this.openAbout}/>
        <Delete show={this.state.show} commande={this.handleDelete}/>
        {
          this.state.lesReactions.length !== 0 && this.state.reactionActuel !== null && (
              <Edit listNoms={this.state.listNoms} setopen={this.handleCloseEdit} open={this.state.openEdit} reactionActuel={this.state.reactionActuel} editReaction={this.editReaction} noEditReaction={this.noEditReaction}/>
          )
        }
        <Create listNoms={this.state.listNoms} setopen={this.handleClose} open={this.state.create} nbreaction={this.state.lesReactions.length} addReaction={this.addReaction}/>
        <MenuHaut openAbout={this.openAbout} setopenEdit={this.handleEdit} commande={this.handleDelete} setReactionActuel={this.setReactionActuel} listReactions={this.state.lesReactions} act={this.state.actif} setopen={this.handleOpen}/>
        <Container style={{marginTop : "60px"}}>
        {
          this.state.lesReactions.length !== 0 && this.state.reactionActuel !== null && (
            <Fragment>
              <Graph changerNbPoints={this.changerNbPoints} pointActuel={this.state.pointActuel} nbPoints={this.state.nbPoints} xMax={this.state.xMax} reactionActuel={this.state.reactionActuel}/>
              <Reaction indexReactifLimitant={this.state.indexReactifLimitant} pointActuel={this.state.pointActuel} xMax={this.state.xMax} nbPoints={this.state.nbPoints} infos={this.state.reactionActuel} avancement={this.state.avancement}/>
              <MenuAvancement xMax={this.state.xMax} pointActuel={this.state.pointActuel} nbPoints={this.state.nbPoints} ModifierPoint={this.ModifierPoint} Play={this.Play} run={this.state.pointActuel+1 <= this.state.nbPoints}/>
            </Fragment>
          )
        } 
        {
          this.state.lesReactions.length === 0 && (
            <Fragment>
                <Message
                  positive
                  icon='file outline'
                  header='Pas encore ajouté une réaction ?'
                  content='Pour ajouter une réaction cliquer sur le bouton + en haut à gauche.'
                  style={{ margin : "auto" }}
                />
                <Message
                  positive
                  icon='info circle'
                  header='Informations'
                  content='Application web crée par SAMUEL JACQUINET'
                  style={{ marginTop : "20px" }}
                />
            </Fragment>
          )
        }      
        </Container>        
      </Fragment>
    )
  }
  
}

export default App1;
