import React, { Fragment , useState, useEffect} from 'react';
import { Container, Menu,Icon,Dropdown, Segment } from 'semantic-ui-react';

const options = [
    { key: '1', text: 'Un réactif est complétement consommé à la fin de la réaction', value: '1' },
    { key: '2', text: 'Tous les réactifs sont completement consommés à la fin de la réaction', value: '2' },
  ];

const options2 = [
    { key: '1', text: 'Deux réactifs et un produit', value: '1' },
    { key: '2', text: 'Deux réactifs et deux produits', value: '2' },
  ];



const SousMenu = ({updateChoix,updateChoix2,isMobile}) => {


    const [choix, setchoix] = useState("1")

    const [choix2, setchoix2] = useState("1")

    useEffect(() => {
      if(localStorage.getItem('choix') !== null){
          setchoix(localStorage.getItem('choix'));
      }
      if(localStorage.getItem('choix2') !== null){
        setchoix2(localStorage.getItem('choix2'));
    }
    }, [])
    

    function handleOptions(e, data){
        setchoix(data.value);
        updateChoix(data.value);
    }

    function handleOptions2(e, data){
        setchoix2(data.value);
        updateChoix2(data.value);
    }

    if(isMobile === false){
        return ( 
            <Container style={{marginTop : "1em"}}>
                <div>
                    <Menu compact style={{float : "left"}}>
                        <Dropdown          
                            selection
                            compact={isMobile === false}
                            value={choix2} 
                            options={options2}
                            onChange={(e,data) => handleOptions2(e,data)}
                        />
                    </Menu>

                    <Menu compact style={{float : "right"}}>
                        <Dropdown          
                            selection
                            compact={isMobile === false}
                            value={choix} 
                            options={options}
                            onChange={(e,data) => handleOptions(e,data)}
                        />
                    </Menu>
                </div>
            </Container>
        )
    }else{
        return ( 
            <Container style={{marginTop : "1em"}}>
                <div style={{ display : "flex", flexDirection : "column", alignContent : "center"}}>
                    <Dropdown          
                            selection
                            value={choix2} 
                            options={options2}
                            onChange={(e,data) => handleOptions2(e,data)}
                    />

                    <Dropdown 
                            style={{marginTop : "1em", marginBottom : "0.5em"}}         
                            selection
                            value={choix} 
                            options={options}
                            onChange={(e,data) => handleOptions(e,data)}
                    />
                </div>
            </Container>
        )
    }
}
 
export default SousMenu;