import React, { Fragment, useEffect, useState  } from 'react';
import { Card, Image } from 'semantic-ui-react';
import MenuApp from './MenuApp';
import app1 from './app1.png';
import app2 from './app2.png';
import { NavLink } from "react-router-dom";
import About0 from './About0';

const Home = () => {

    const [width, setWidth] = useState(window.innerWidth);

    const [about, setabout] = useState(false)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    function handleAbout(value){
        setabout(value);
    }

    return ( 
        <Fragment>
                  <About0 show={about} openAbout={handleAbout}/>
                  <MenuApp openAbout={handleAbout}/>
                  <div style={{ marginTop: width > 600 ? "150px" : "20px"}}>
                      <Card.Group centered>
                      <Card as={NavLink} to={'/app1'}>
                            <Image src={app2} wrapped ui={false} />
                            <Card.Content>
                            <Card.Header>Application 1</Card.Header>
                            <Card.Meta>
                                <span>version 1.0.9</span>
                            </Card.Meta>
                            <Card.Description>
                                Application web vous permettant d’étudier le principe des réactions totales.
                            </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card as={NavLink} to={'/app2'}>
                            <Image src={app1} wrapped ui={false} />
                            <Card.Content>
                            <Card.Header>Application 2</Card.Header>
                            <Card.Meta>
                                <span>version 1.1.0</span>
                            </Card.Meta>
                            <Card.Description>
                                Application web vous permettant d’étudier les réactions totales.
                            </Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </div>
              </Fragment>
     );
}
 
export default Home;