import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';
import React, {useState, Fragment} from 'react'
import { Segment, Button, Icon } from 'semantic-ui-react';

const Formule3 = ({choixSelected,reactionActuel,choix}) => {

    const [open, setopen] = useState(false)

    if(choix === "1"){
    return ( 
        <Segment style={{paddingTop : "0.4em", paddingBottom : "1em", minHeight : "2.5em", marginBottom : "1em", overflowX : "auto"}}>
            <div style={{display : "flex", flexDirection : "row", justifyContent : "space-between", height : "2em", alignItems : "center", cursor : "pointer"}}>      
                <div style={{float : "left", marginTop : "0.2em"}} onClick={ (e) => setopen(!open)}>
                    <BlockMath>{`\\textbf{Détermination de la composition finale du système (méthode 2) :}`}</BlockMath>
                </div>
                <Button circular icon style={{ float : "right", backgroundColor : "transparent", padding : "0em" }} onClick={ (e) => setopen(!open)}>
                    <Icon size='large' name={ open===false ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'} />
                </Button>
                </div>
            <div>
                {
                    choixSelected === "1" ? (
                        open === true && (
                            <Fragment>
                            {
                                reactionActuel.produits.length === 1 ? (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme A est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{` n(A)_{initial} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A)}`}</BlockMath></p>
                                        <p><BlockMath>{` \\text{Donc on a : } x_{final} = \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{${String(parseFloat(reactionActuel.reactifs[0].nbmole)).replace(".",",")}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a : } n(B)_{final} = n(B)_{initial} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times x_{final} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{D'ou on a :}`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{final} = ${reactionActuel.reactifs[1].nbmole} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole) - parseInt(reactionActuel.reactifs[1].nbstoechiometrique)*(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique))).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(C)_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseInt(reactionActuel.produits[0].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique).toFixed(2)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de C)}`}</BlockMath></p>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme A est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{` n(A)_{initial} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A)}`}</BlockMath></p>
                                        <p><BlockMath>{` \\text{Donc on a : } x_{final} = \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{${reactionActuel.reactifs[0].nbmole}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a : } n(B)_{final} = n(B)_{initial} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times x_{final} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{D'ou on a :}`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{final} = ${reactionActuel.reactifs[1].nbmole} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole) - parseInt(reactionActuel.reactifs[1].nbstoechiometrique)*(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique))).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(C)_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseFloat(parseInt(reactionActuel.produits[0].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de C)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et : } n(D)_{final} = ${reactionActuel.produits[1].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[1].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseInt(reactionActuel.produits[1].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique).toFixed(2)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de D)}`}</BlockMath></p>
                                    </Fragment>
                                )
                            }
                                
                            </Fragment>
                        )
                    ) : (
                        open === true && (
                            <Fragment>
                            {
                                reactionActuel.produits.length === 1 ? (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme B est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{initial} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B)}`}</BlockMath></p>
                                        <p><BlockMath>{` \\text{Donc on a : } x_{final} = \\displaystyle\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}=\\frac{${reactionActuel.reactifs[1].nbmole}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a : } n(A)_{final} = n(A)_{initial} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times x_{final} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{D'ou on a :}`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{final} = ${reactionActuel.reactifs[0].nbmole} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole) - parseInt(reactionActuel.reactifs[0].nbstoechiometrique)*(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique))).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(C)_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseInt(reactionActuel.produits[0].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de C)}`}</BlockMath></p>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                    <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme B est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{initial} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B)}`}</BlockMath></p>
                                        <p><BlockMath>{` \\text{Donc on a : } x_{final} = \\displaystyle\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}=\\frac{${reactionActuel.reactifs[1].nbmole}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a : } n(A)_{final} = n(A)_{initial} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times x_{final} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{D'ou on a :}`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{final} = ${reactionActuel.reactifs[0].nbmole} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole) - parseInt(reactionActuel.reactifs[0].nbstoechiometrique)*(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique))).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(C)_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseInt(reactionActuel.produits[0].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de C)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et : } n(D)_{final} = ${reactionActuel.produits[1].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[1].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseFloat(parseInt(reactionActuel.produits[1].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de D)}`}</BlockMath></p>
                                    </Fragment>
                                )
                            }
                            </Fragment>
                        )
                    )
                }
            </div>
        </Segment>
     )
    }else{
        return (
            <Segment style={{paddingTop : "0.4em", paddingBottom : "1em", minHeight : "2.5em", marginBottom : "1em", overflowX : "auto"}}>
            <div style={{display : "flex", flexDirection : "row", justifyContent : "space-between", height : "2em", alignItems : "center", cursor : "pointer"}}>
            <div style={{float : "left", marginTop : "0.2em"}} onClick={ (e) => setopen(!open)}>
                <BlockMath>{`\\textbf{Détermination de la composition finale du système (méthode 2) :}`}</BlockMath>
            </div>
            <Button circular icon style={{ float : "right", backgroundColor : "transparent", padding : "0em" }} onClick={ (e) => setopen(!open)}>
                <Icon size='large' name={ open===false ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'} />
            </Button>
            </div>
            <div>
                {
                    open === true && (
                            <Fragment>
                            {
                                reactionActuel.produits.length === 1 ? (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme tous les réactifs sont complétement consommées à la fin de la réaction on a : }`}</BlockMath></p>
                                        <p><BlockMath>{` n(A)_{initial} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A)}`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{initial} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B)}`}</BlockMath></p>
                                        <p><BlockMath>{` \\text{Donc on a : } x_{final} = \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(C)_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseInt(reactionActuel.produits[0].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de C)}`}</BlockMath></p>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme tous les réactifs sont complétement consommées à la fin de la réaction on a : }`}</BlockMath></p>
                                        <p><BlockMath>{` n(A)_{initial} ~-~ ${reactionActuel.reactifs[0].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A)}`}</BlockMath></p>
                                        <p><BlockMath>{` n(B)_{initial} ~-~ ${reactionActuel.reactifs[1].nbstoechiometrique} \\times x_{final} = 0 `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B)}`}</BlockMath></p>
                                        <p><BlockMath>{` \\text{Donc on a : } x_{final} = \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(C)_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[0].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseInt(reactionActuel.produits[0].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de C)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a : } n(D)_{final} = ${reactionActuel.produits[1].nbstoechiometrique} \\times x_{final} = ${reactionActuel.produits[1].nbstoechiometrique} \\times ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} = ${String(parseInt(reactionActuel.produits[1].nbstoechiometrique)*parseFloat(reactionActuel.reactifs[1].nbmole)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.produits[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de D)}`}</BlockMath></p>
                                    </Fragment>
                                )
                            }
                                
                            </Fragment>
                        )
                }
            </div>
        </Segment>
        )
    }
}
 
export default Formule3;