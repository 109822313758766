import React, { Fragment } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import App1 from './components/App1';
import App2 from './components2/App2';
import Home from './components0/Home';

const App = () => {
  return ( 
    <Fragment>      
        <Router>         
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/app2" element={<App1/>}/>
            <Route path="/app1" element={<App2/>}/>
            <Route path="*" element={<Home/>}/>
          </Routes>
        </Router>
    </Fragment>  
   );
}
 
export default App;
