import React,{useState, useEffect} from 'react';
import { Modal, Button, Icon, Header } from 'semantic-ui-react';

const Delete = ({show,commande}) => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
      setOpen(show);
    }, [show])
    
    function Close(){
        setOpen(false);
        commande(false);
    }

    function CloseDelete(){
        setOpen(false);
        commande(true);
    }

    return (
        <Modal
        basic
        open={open}
        size='small'
        >
        <Header icon>
            <Icon name='trash alternate' />
            Supprimer la réaction
        </Header>
        <Modal.Content>
            <p>
            Etes vous sur de vouloir supprimer la réaction ?
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button basic color='red' inverted onClick={() => Close()}>
            <Icon name='remove' /> Non
            </Button>
            <Button color='green' inverted onClick={() => CloseDelete()}>
            <Icon name='checkmark' /> Oui
            </Button>
        </Modal.Actions>
        </Modal>
    )
    
}
 
export default Delete;