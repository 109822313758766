import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';
import React, {useState, Fragment} from 'react'
import { Segment, Button, Icon } from 'semantic-ui-react';

const Formule2 = ({choixSelected,reactionActuel,choix}) => {

    const [open, setopen] = useState(false)

    if(choix === "1"){
    return ( 
        <Segment style={{paddingTop : "0.4em", paddingBottom : "1em", minHeight : "2.5em", marginBottom : "1em", overflowX : "auto"}}>
            <div style={{display : "flex", flexDirection : "row", justifyContent : "space-between", height : "2em", alignItems : "center", cursor : "pointer"}}>
            <div style={{float : "left", marginTop : "0.2em"}} onClick={ (e) => setopen(!open)}>
            <BlockMath>
                {`\\textbf{Détermination de la composition finale du système (méthode 1) :}`}
            </BlockMath>
            </div>
            <Button circular icon style={{ float : "right", backgroundColor : "transparent", padding : "0em" }} onClick={ (e) => setopen(!open)}>
                <Icon size='large' name={ open===false ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'} />
            </Button>
            </div>
            <div>
                {
                    choixSelected === "1" ? (
                        open === true && (
                            <Fragment>
                            {
                                reactionActuel.produits.length === 1 ? (
                                    <div style={{marginTop : "1.5em"}}>
                                        <p style={{justifyContent : "left"}}><BlockMath>{`\\text{Comme A est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{`\\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(C)_{final}}{${reactionActuel.produits[0].nbstoechiometrique}}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A et } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ celui de C)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Donc on a : } \\displaystyle n(C)_{final} = \\frac{n(A)_{initial}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)*parseInt(reactionActuel.produits[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a :}`}</BlockMath></p>
                                        <p><BlockMath>{`n(B)_{final} = n(B)_{initial} ~-~ \\frac{${reactionActuel.reactifs[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} \\times n(A)_{initial} = ${String(parseFloat(reactionActuel.reactifs[1].nbmole-(parseInt(reactionActuel.reactifs[1].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)*reactionActuel.reactifs[0].nbmole)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B et } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ celui de A)}`}</BlockMath></p>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{` \\text{Comme A est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{`\\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(C)_{final}}{${reactionActuel.produits[0].nbstoechiometrique}}=\\frac{n(D)_{final}}{${reactionActuel.produits[1].nbstoechiometrique}}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A, } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ celui de C et } ${reactionActuel.produits[1].nbstoechiometrique} \\text{ celui de D)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Donc on a : } \\displaystyle n(C)_{final} = \\frac{n(A)_{initial}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)*parseInt(reactionActuel.produits[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a : } \\displaystyle n(D)_{final} = \\frac{n(A)_{initial}\\times ${reactionActuel.produits[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)*parseInt(reactionActuel.produits[1].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a :}`}</BlockMath></p>
                                        <p><BlockMath>{`n(B)_{final} = n(B)_{initial} ~-~ \\frac{${reactionActuel.reactifs[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} \\times n(A)_{initial} = ${String(parseFloat(reactionActuel.reactifs[1].nbmole-(parseInt(reactionActuel.reactifs[1].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)*reactionActuel.reactifs[0].nbmole)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B et } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ celui de A)}`}</BlockMath></p>
                                    </Fragment>
                                )
                            }
                                
                            </Fragment>
                        )
                    ) : (
                        open === true && (
                            <Fragment>
                            {
                                reactionActuel.produits.length === 1 ? (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme B est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{`\\displaystyle\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}=\\frac{n(C)_{final}}{${reactionActuel.produits[0].nbstoechiometrique}}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A et } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ celui de C)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Donc on a : } \\displaystyle n(C)_{final} = \\frac{n(B)_{initial}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)*parseInt(reactionActuel.produits[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a :}`}</BlockMath></p>
                                        <p><BlockMath>{`n(A)_{final} = n(A)_{initial} ~-~ \\frac{${reactionActuel.reactifs[0].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} \\times n(B)_{initial} = ${String(parseFloat(reactionActuel.reactifs[0].nbmole-(parseInt(reactionActuel.reactifs[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)*reactionActuel.reactifs[1].nbmole)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A et } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ celui de B)}`}</BlockMath></p>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{` \\text{Comme B est le réactif limitant on a alors : }`}</BlockMath></p>
                                        <p><BlockMath>{` \\displaystyle\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}=\\frac{n(C)_{final}}{${reactionActuel.produits[0].nbstoechiometrique}}=\\frac{n(D)_{final}}{${reactionActuel.produits[1].nbstoechiometrique}}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ le coefficient stœchiometrique de B, } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ celui de C et } ${reactionActuel.produits[1].nbstoechiometrique} \\text{ celui de D)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Donc on a : } \\displaystyle n(C)_{final} = \\frac{n(B)_{initial}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[1].nbmole).replace(".",",")}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)*parseInt(reactionActuel.produits[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a : } \\displaystyle n(D)_{final} = \\frac{n(B)_{initial}\\times ${reactionActuel.produits[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[1].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[1].nbmole)*parseInt(reactionActuel.produits[1].nbstoechiometrique)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{De même on a :}`}</BlockMath></p>
                                        <p><BlockMath>{` n(A)_{final} = n(A)_{initial} ~-~ \\frac{${reactionActuel.reactifs[0].nbstoechiometrique}}{${reactionActuel.reactifs[1].nbstoechiometrique}} \\times n(B)_{initial} = ${String(parseFloat(reactionActuel.reactifs[0].nbmole-(parseInt(reactionActuel.reactifs[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[1].nbstoechiometrique)*reactionActuel.reactifs[1].nbmole)).toFixed(2)).replace(".",",")} \\text{ mol} `}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A et } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ celui de B)}`}</BlockMath></p>
                                    </Fragment>
                                )
                            }  
                            </Fragment>
                        )
                    )
                }
            </div>
        </Segment>
     )
    }else{
        return (
            <Segment style={{paddingTop : "0.4em", paddingBottom : "1em", minHeight : "2.5em", marginBottom : "1em", overflowX : "auto"}}>
            <div style={{display : "flex", flexDirection : "row", justifyContent : "space-between", height : "2em", alignItems : "center", cursor : "pointer"}}>
            <div style={{float : "left", marginTop : "0.2em"}} onClick={ (e) => setopen(!open)}>
                <BlockMath>{`\\textbf{Détermination de la composition finale du système (méthode 1) :}`}</BlockMath>
            </div>
            <Button circular icon style={{ float : "right", backgroundColor : "transparent", padding : "0em" }} onClick={ (e) => setopen(!open)}>
                <Icon size='large' name={ open===false ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'} />
            </Button>
            </div>
            <div>
                {
                    open === true && (
                            <Fragment>
                            {
                                reactionActuel.produits.length === 1 ? (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{`\\text{Comme tous les réactifs sont complétement consommées à la fin de la réaction on a : }`}</BlockMath></p>
                                        <p><BlockMath>{` \\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}=\\frac{n(C)_{final}}{${reactionActuel.produits[0].nbstoechiometrique}}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A, } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ celui de B et } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ celui de C)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Donc on a : } \\displaystyle n(C)_{final} = \\frac{n(A)_{initial}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)*parseInt(reactionActuel.produits[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p style={{ marginTop : "1.5em"}}><BlockMath>{` \\text{Comme tous les réactifs sont complétement consommées à la fin de la réaction on a : }`}</BlockMath></p>
                                        <p><BlockMath>{`\\displaystyle\\frac{n(A)_{initial}}{${reactionActuel.reactifs[0].nbstoechiometrique}}=\\frac{n(B)_{initial}}{${reactionActuel.reactifs[1].nbstoechiometrique}}=\\frac{n(C)_{final}}{${reactionActuel.produits[0].nbstoechiometrique}}=\\frac{n(D)_{final}}{${reactionActuel.produits[1].nbstoechiometrique}}`}</BlockMath></p>
                                        <p style={{fontSize : "0.8em", fontStyle : "oblique"}}><BlockMath>{`\\text{(Avec } ${reactionActuel.reactifs[0].nbstoechiometrique} \\text{ le coefficient stœchiometrique de A, } ${reactionActuel.reactifs[1].nbstoechiometrique} \\text{ celui de B, } ${reactionActuel.produits[0].nbstoechiometrique} \\text{ celui de C et } ${reactionActuel.produits[1].nbstoechiometrique} \\text{ celui de de D)}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Donc on a : } \\displaystyle n(C)_{final} = \\frac{n(A)_{initial}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[0].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)*parseInt(reactionActuel.produits[0].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                        <p><BlockMath>{`\\text{Et on a } \\displaystyle n(D)_{final} = \\frac{n(A)_{initial}\\times ${reactionActuel.produits[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = \\displaystyle\\frac{${String(reactionActuel.reactifs[0].nbmole).replace(".",",")}\\times ${reactionActuel.produits[1].nbstoechiometrique}}{${reactionActuel.reactifs[0].nbstoechiometrique}} = ${String(parseFloat(parseFloat(reactionActuel.reactifs[0].nbmole)*parseInt(reactionActuel.produits[1].nbstoechiometrique)/parseInt(reactionActuel.reactifs[0].nbstoechiometrique)).toFixed(2)).replace(".",",")} \\text{ mol}`}</BlockMath></p>
                                    </Fragment>
                                )
                            }
                                
                            </Fragment>
                        )
                }
            </div>
        </Segment>
        )
    }
}
 
export default Formule2;