import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const MenuHaut2 = ({openAbout}) => {

    const [actif, setactif] = useState(null);
    const [activeItem, setactiveItem] = useState(null);

    const navigate = useNavigate()

    const faireRedirection = () =>{ 
        let url = "/";
        navigate(url);
    }

    return ( 
        <Fragment>
            <Menu inverted attached size="mini">
                <Menu.Menu position='right'>
                    <Button 
                        circular  
                        onClick={faireRedirection}
                        style={{ 
                                padding : "5px", 
                                backgroundColor : "transparent",
                                verticalAlign: "middle"
                        }} 
                        primary
                        >
                        <Icon name="home"/>
                    </Button>
                    <Button 
                        circular  
                        style={{ padding : "5px", 
                        backgroundColor : "transparent"
                        }} 
                        primary
                        onClick={ (e) => openAbout(true)}
                        >
                        <Icon name="question circle outline"/>
                    </Button>
                </Menu.Menu>
            </Menu>
        </Fragment>
     );
}
 
export default MenuHaut2;